import React, { useState } from "react";
import Country from './country'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import CountryCallingcode from './countryCallingCode'

export const CountryManagement = () => {

  const [key, setKey] = useState('Country');

  const spanStyle = {
    'marginLeft': '4.5rem'
  }

  return (
    <div className="container-fluid country-management" >
      <Tabs style={spanStyle} id="country-management-tab"
        activeKey={key} onSelect={(k) => setKey(k)} mountOnEnter={true} >

        <Tab className="tab-country" eventKey="Country" title="Country">
          <Country />
        </Tab>
        <Tab className="tab-country" eventKey="CountryCode" title="SMS Sender IDs">
          <CountryCallingcode />
        </Tab>

      </Tabs>
    </div>
  )
}

export default withAuthorization(
  CountryManagement,
  PageAuthorizations.CountryManagement.authorizedRoles
);
