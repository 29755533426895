import React, { Component } from 'react'
import { PageAuthorizations } from '../auth/pageAuthorization'
import { withAuthorization } from '../components/higher-order-components/AuthorizationHOC'
import { withReleaseNotes } from '../components/higher-order-components/ReleaseNotesHOC'
import { ReleaseNotesContent } from '../components/ReleaseNotesContent'

export class WhatsNew extends Component {

  render() {
    const { releaseNotes } = this.props
    return (
      <div className="container">
        <ReleaseNotesContent releaseNotes={releaseNotes} />
      </div>
    )
  }
}

export default withAuthorization(
  withReleaseNotes(WhatsNew),
  PageAuthorizations.WhatsNew.authorizedRoles
)