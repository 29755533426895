import React, { useState, useEffect } from 'react'

const DEFAULT_TIMEOUT = 40 * 1000 // 40 seconds
const MAX_TIMEOUT = 300 * 1000 // 5 minutes
let timeout = 0

const Loading = ({ isLoading, maxTimeout, forFullViewport = true }) => {

  const [ hasTimedout, setHasTimedOut ] = useState(false)

  useEffect(() => {

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setHasTimedOut({
        hasTimedout: Boolean(isLoading)
      })
    }, maxTimeout ? MAX_TIMEOUT : DEFAULT_TIMEOUT)

    if(!isLoading) {
      clearTimeout(timeout)
    }
 
    return () => {
      clearTimeout(timeout)
    }
  }, [isLoading])

  const renderLoadingIndicator = () => (
      <div className={`loading ${(isLoading ? 'visible' : 'invisible')} ${forFullViewport ? '' : 'position-relative'}`} data-testid="loading" >
        <div className="wrapper">
          <div className="inner">
            <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
          </div>
        </div>
      </div>
  )

  const renderErrorMessage = () => (
        <div className={`loading visible ${forFullViewport ? '' : 'position-relative'}`}>
          <div className="wrapper">
            <div className="inner">
              {'Unknown Error'}
            </div>
          </div>
        </div>
      )

  return hasTimedout ?  renderErrorMessage() : renderLoadingIndicator()
}

export default Loading