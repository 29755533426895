import React, { Component } from "react";
import ManageAccounts from "../../../services/ManageAccounts";
import { Formik, Form, Field } from "formik";
import InputField from "../../../formFields/InputField";
import { Button } from "react-bootstrap";
import ResetAccountTable from "./resetAccountTable";
import Loading from "../../loading";
import { SuccessPopup } from "../../../popups/SuccessPopup";
import * as AdalFacade from "../../../auth/adalFacade";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'

let SupportId = getSupportId()

export default class SearchAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: {},
      currentCount: 0,
      loading: false,
      title: "",
      message: "",
      shouldShowPopup: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onClick = () => {
    this.setState({ shouldShowPopup: false });
  };

  fetchData(values) {
    this.setState({ loading: true });

    ManageAccounts.fetchAccountDetails(values.username)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.status === 404) {
            reject("NotFound");
          } else {
            resolve(response);
          }
        });
      })
      .then((data) => {
        this.setState({ accounts: data, loading: false });
      })
      .catch((err) => {
        this.setState({ accounts: {}, loading: false });
        console.log(err);
        if (err) {
          this.setState({
            title: "User not found",
            message: values.username + " is not found",
            shouldShowPopup: true,
          });
        }
      });
  }

  correlationContext = CorrelationContext["ManageAccounts_UpdateTelecareFeature"] + SupportId;
  isTC3ButtonAccessibleForThisRole = () => (["admin", "ambassadoruser"].filter(_ => AdalFacade.getUserRoles().map(role => role.toLowerCase()).includes(_)).length > 0)

  updateTelecareFeature = async (userName, isEnabled, correlationContext) => {
    try {
      this.setState({ loading: true });
      const response = await ManageAccounts.updateTelecareFeature(
        userName,
        isEnabled
      );

      const data = await response.json();
      this.setState({
        loading: false, title: "Launch darkly", message: "Successfully updated telecare feature flag in launch darkly", shouldShowPopup: true, accounts: {
          ...this.state.accounts,
          isUserPresentForTelecareFeature: data
        }
      })
    } catch (e) {
      this.setState({ loading: false, title: "Unable to talk to launch darkly", message: "Unable to update telecare feature flag in launch darkly", shouldShowPopup: true, })
      console.error(e);
    }
  };

  getTable = () => {
    const isTC3ButtonAccessibleForCurrentRole =
      this.isTC3ButtonAccessibleForThisRole();
    return (
      <div>
        {this.state.accounts &&
          this.state.accounts.resetAccounts &&
          this.state.accounts.resetAccounts.length > 0 ? (
          <div>
            <div >
              <h3>Account Data </h3>
              <ResetAccountTable
                accounts={this.state.accounts.resetAccounts}
                isTC3ButtonAccessibleForCurrentRole={isTC3ButtonAccessibleForCurrentRole}
                isUserPresentForTelecareFeature={this.state.accounts.isUserPresentForTelecareFeature}
                updateTelecareFeature={this.updateTelecareFeature}
                isTenatAccountDetails={false}
              />
            </div>
            <div>
              <h3>Tenant Account Data</h3>
              <ResetAccountTable
                accounts={this.state.accounts.tenantUsers}
                isTenatAccountDetails={true}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };
  render() {
    return (
      <div className="container-fluid mt-5 ml-4">
        <h3>Search Telecare Account</h3>
        {this.state.shouldShowPopup && (
          <SuccessPopup
            title={this.state.title}
            message={this.state.message}
            shouldHide={this.onClick}
          />
        )}
        <Formik
          onSubmit={(values) => this.fetchData(values)}
          initialValues={{ username: "" }}
        >
          <Form className="container-fluid">
            <div className="row">
              <div className="col-md-6 form-group">
                <Field
                  name="username"
                  type="text"
                  className="input-wrapper"
                  component={InputField}
                  placeholder={"Please enter a username"}
                />
              </div>
              <div className="col-md-2 form-group">
                <Button
                  className="primary-button"
                  variant="success"
                  type="submit"
                >
                  Search
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
        {this.state.loading ? (
          <Loading isLoading={this.state.loading} />
        ) : this.getTable()}
      </div>
    );
  }
}
SearchAccount.displayName = "SearchAccount";
