export const HcpStatusFilterOption = {
  All: " ",
  PENDING: "PENDING",
  REJECTED: "REGISTRATION_REJECTED",
  ACCEPTED: "ACTIVE",
};

export const HcpStatus = {
    PENDING: "PENDING",
    REGISTRATION_REJECTED: "REGISTRATION REJECTED",
    ACTIVE: "ACCEPTED",
  };

export const PageSize = [25,50,75,100,1000]; 

export const AppInsightCloudRoleName = 'service-portal-frontend'

export const CorrelationContext = {
  //Manage users
    ManageUsers_GetAccountManager:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=GetAccountManager,service-portal-workflow-supportId=',
    ManageUsers_GetAccountManagerById:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=GetAccountManagerById,service-portal-workflow-supportId=',
    ManageUsers_AddAccountManager:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=AddAccountManager,service-portal-workflow-supportId=',
    ManageUsers_EditAccountManager:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=EditAccountManager,service-portal-workflow-supportId=',
    ManageUsers_UpdateAccountManager:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=UpdateAccountManager,service-portal-workflow-supportId=',
    ManageUsers_DeleteAccountManager:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=DeleteAccountManager,service-portal-workflow-supportId=',
    ManageUsers_SearchUserByEmail:
    'service-portal-workflow-name=ManageUsers,service-portal-workflow-step=SearchUserByEmail,service-portal-workflow-supportId=',
    
    //Manage Accounts
    ManageAccounts_GetAccountByUserName:
    'service-portal-workflow-name=ManageAccounts,service-portal-workflow-step=GetAccountByUserName,service-portal-workflow-supportId=',
    ManageAccounts_PasswordResetByUserName:
    'service-portal-workflow-name=ManageAccounts,service-portal-workflow-step=PasswordResetByUserName,service-portal-workflow-supportId=',
    ManageAccounts_IsUserPresent:
    'service-portal-workflow-name=ManageAccounts,service-portal-workflow-step=IsUserPresent,service-portal-workflow-supportId=',
    ManageAccounts_UpdateTelecareFeature:
    'service-portal-workflow-name=ManageAccounts,service-portal-workflow-step=UpdateTelecareFeature,service-portal-workflow-supportId=',
    
    //Manage Country
    ManageCountry_GetCountries:
    'service-portal-workflow-name=ManageCountry,service-portal-workflow-step=GetCountries,service-portal-workflow-supportId=',
    ManageCountry_SaveCountry:
    'service-portal-workflow-name=ManageCountry,service-portal-workflow-step=SaveCountry,service-portal-workflow-supportId=',
    ManageCountry_DeleteCountry:
    'service-portal-workflow-name=ManageCountry,service-portal-workflow-step=DeleteCountry,service-portal-workflow-supportId=',
    ManageCountry_AddCountry:
    'service-portal-workflow-name=ManageCountry,service-portal-workflow-step=AddCountry,service-portal-workflow-supportId=',

    //Manage HCP
    ManageHcp_GetHcpById:
    'service-portal-workflow-name=ManageHcp,service-portal-workflow-step=GetHcpById,service-portal-workflow-supportId=',
    ManageHcp_EditHcp:
    'service-portal-workflow-name=ManageHcp,service-portal-workflow-step=EditHcp,service-portal-workflow-supportId=',
    ManageHcp_EditHcpState:
    'service-portal-workflow-name=ManageHcp,service-portal-workflow-step=EditHcpState,service-portal-workflow-supportId=',
    ManageHcp_FetchData:
    'service-portal-workflow-name=ManageHcp,service-portal-workflow-step=FetchData,service-portal-workflow-supportId=',
  
  //Manage Org
  ManageOrg_GetOrgById:
  'service-portal-workflow-name=ManageOrg,service-portal-workflow-step=GetOrgById,service-portal-workflow-supportId=',
  ManageOrg_GetOrgByEmail:
  'service-portal-workflow-name=ManageOrg,service-portal-workflow-step=GetOrgByEmail,service-portal-workflow-supportId=',
  ManageOrg_SaveOrg:
  'service-portal-workflow-name=ManageOrg,service-portal-workflow-step=SaveOrg,service-portal-workflow-supportId=', 
  
  //Merge Org
  MergeOrg_MergeOrganizations:
  'service-portal-workflow-name=MergeOrg,service-portal-workflow-step=MergeOrganizations,service-portal-workflow-supportId='

}

export const getOptionsWithCorrelationContext = (options, correlationContext) => {
  correlationContext && (options.headers["Correlation-Context"] = correlationContext);
  return options;
};

export const HcpManagementFilter = {
    "Organization Name": "orgName",
    "Organization Id": "orgId",
    "Email Address of Organization": "orgEmail",
    "Email Address of Admin User": "employeeEmail",
    "Signia Account Number": "accountNumber"
};

export const HcpTristate = ["REGISTRATION_REJECTED", "PENDING", "ACTIVE"];

export const Tooltips = [
  "Click here to reject",
  "REJECTED",
  "PENDING",
  "Click here to accept",
  "ACCEPTED",
];

export const countries = {
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua and Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AN: "Netherlands Antilles",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AZ: "Azerbaijan",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthelemy",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos Islands",
  CD: "Democratic Republic of the Congo",
  CF: "Central African Republic",
  CG: "Republic of the Congo",
  CH: "Switzerland",
  CI: "Ivory Coast",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cape Verde",
  CW: "Curacao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands",
  FM: "Micronesia, Federated States of",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia and South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "Saint Kitts and Nevis",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "Macedonia",
  ML: "Mali",
  MM: "Burma",
  MN: "Mongolia",
  MO: "Macau",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  PN: "Pitcairn Islands",
  PR: "Puerto Rico",
  PS: "Palestine",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  SI: "Slovenia",
  SJ: "Svalbard",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "Sao Tome and Principe",
  SV: "El Salvador",
  SX: "Saint Martin",
  SY: "Syria",
  SZ: "Swaziland",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TF: "French Southern and Antarctic Lands",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turkey",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UA: "Ukraine",
  UG: "Uganda",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Holy See (Vatican City)",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const continentsWithName = {
  AF: "Africa",
  AS: "Asia",
  AN: "Antarctica",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};

export const locales = ["DE", "EN"];

export const continents = {
  AS: [
    "AF",
    "AM",
    "AZ",
    "BH",
    "BD",
    "BT",
    "BN",
    "CN",
    "GE",
    "HK",
    "IN",
    "ID",
    "IQ",
    "IR",
    "IL",
    "JP",
    "YE",
    "JO",
    "KH",
    "KZ",
    "QA",
    "KG",
    "KW",
    "LA",
    "LB",
    "MO",
    "MY",
    "MV",
    "MN",
    "MM",
    "NP",
    "KP",
    "OM",
    "TL",
    "PK",
    "PS",
    "PH",
    "SA",
    "SG",
    "LK",
    "KR",
    "SY",
    "TJ",
    "TW",
    "TH",
    "TR",
    "TM",
    "UZ",
    "AE",
    "VN",
    "CY",
  ],
  AF: [
    "EG",
    "DZ",
    "AO",
    "GQ",
    "ET",
    "BJ",
    "BW",
    "IO",
    "BF",
    "BI",
    "CD",
    "DJ",
    "CI",
    "ER",
    "GA",
    "GM",
    "GH",
    "GN",
    "GW",
    "CM",
    "CV",
    "KE",
    "KM",
    "CG",
    "LS",
    "LR",
    "LY",
    "MG",
    "MW",
    "ML",
    "MA",
    "MR",
    "MU",
    "YT",
    "MZ",
    "NA",
    "NE",
    "NG",
    "RE",
    "RW",
    "ZM",
    "ST",
    "SN",
    "SC",
    "SL",
    "ZW",
    "SO",
    "SH",
    "ZA",
    "SD",
    "SS",
    "SZ",
    "TZ",
    "TG",
    "TD",
    "TN",
    "UG",
    "EH",
    "CF",
  ],
  EU: [
    "AL",
    "AD",
    "BY",
    "BE",
    "BA",
    "BG",
    "DK",
    "DE",
    "EE",
    "FO",
    "FI",
    "FR",
    "GI",
    "GR",
    "GG",
    "IM",
    "IE",
    "IS",
    "IT",
    "JE",
    "XK",
    "HR",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "MK",
    "MD",
    "MC",
    "ME",
    "NL",
    "NO",
    "AT",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "SE",
    "CH",
    "RS",
    "SK",
    "SI",
    "ES",
    "SJ",
    "CZ",
    "UA",
    "HU",
    "VA",
    "GB",
  ],
  OC: [
    "AS",
    "CK",
    "FJ",
    "PF",
    "GU",
    "KI",
    "MH",
    "FM",
    "NR",
    "NC",
    "NU",
    "MP",
    "PW",
    "PG",
    "PN",
    "SB",
    "WS",
    "TK",
    "TO",
    "TV",
    "VU",
    "WF",
    "AU",
    "CC",
    "NZ",
    "NF",
    "CX",
  ],
  NA: [
    "AI",
    "AG",
    "AW",
    "BS",
    "BB",
    "BM",
    "DM",
    "DO",
    "GD",
    "GL",
    "GP",
    "HT",
    "JM",
    "VG",
    "VI",
    "KY",
    "CA",
    "CU",
    "MQ",
    "MX",
    "MS",
    "AN",
    "PR",
    "KN",
    "LC",
    "PM",
    "VC",
    "TT",
    "TC",
    "US",
    "BZ",
    "CR",
    "SV",
    "GT",
    "HN",
    "NI",
    "PA",
    "CW",
    "BL",
    "SX",
  ],
  AN: ["AQ", "BV", "TF", "HM", "GS"],
  SA: [
    "AR",
    "BO",
    "BR",
    "CL",
    "EC",
    "FK",
    "GF",
    "GY",
    "CO",
    "PY",
    "PE",
    "SR",
    "UY",
    "VE",
  ],
};

export const countryCodes = [
  "AF",
  "EG",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "GQ",
  "AR",
  "AM",
  "AW",
  "AZ",
  "ET",
  "AU",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CL",
  "CN",
  "CK",
  "CR",
  "CW",
  "DK",
  "CD",
  "DE",
  "DM",
  "DO",
  "DJ",
  "EC",
  "SV",
  "CI",
  "ER",
  "EE",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "GH",
  "GI",
  "GD",
  "GR",
  "GL",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "HN",
  "HK",
  "IN",
  "ID",
  "IM",
  "IQ",
  "IR",
  "IE",
  "IS",
  "IL",
  "IT",
  "JM",
  "JP",
  "YE",
  "JE",
  "JO",
  "VG",
  "VI",
  "KY",
  "KH",
  "CM",
  "CA",
  "CV",
  "KZ",
  "QA",
  "KE",
  "KG",
  "KI",
  "CC",
  "CO",
  "KM",
  "CG",
  "XK",
  "HR",
  "CU",
  "KW",
  "LA",
  "LS",
  "LV",
  "LB",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MA",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MK",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NC",
  "NZ",
  "NI",
  "NL",
  "AN",
  "NE",
  "NG",
  "NU",
  "KP",
  "MP",
  "NF",
  "NO",
  "OM",
  "AT",
  "TL",
  "PK",
  "PS",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "RE",
  "RW",
  "RO",
  "RU",
  "SB",
  "ZM",
  "WS",
  "SM",
  "BL",
  "ST",
  "SA",
  "SE",
  "CH",
  "SN",
  "RS",
  "SC",
  "SL",
  "ZW",
  "SG",
  "SX",
  "SK",
  "SI",
  "SO",
  "ES",
  "LK",
  "SH",
  "KN",
  "LC",
  "PM",
  "VC",
  "ZA",
  "SD",
  "GS",
  "KR",
  "SS",
  "SR",
  "SJ",
  "SZ",
  "SY",
  "TJ",
  "TW",
  "TZ",
  "TH",
  "TG",
  "TK",
  "TO",
  "TT",
  "TD",
  "CZ",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "HU",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "AE",
  "US",
  "GB",
  "VN",
  "WF",
  "CX",
  "EH",
  "CF",
  "CY",
];

export const errorResponse = {
  400: "Bad Request",
  401: "You are not Authorized for this Page",
  403: "You are not Authorized for this Page",
  500: "Something went wrong",
};

export const maxSizeOfTextboxComponent = 5;

export const mergeOrganizationTimeout = 600 * 1000;