import { adalApiFetch } from "../auth/adalConfig";
import { getOptionsWithCorrelationContext } from '../helper/constants';

export const mergeOrganizations = async (payload, correlationContext = "") => {
  let options = {
    method: "post",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/ json" }
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, "/api/merge", options)
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch((err) => {
      console.log(err);
    });
};
