import React from "react";
import { Field, getIn } from 'formik';


export const getTouchedObj = (errorList) => {
  const touched = {};
  Object.keys(errorList).forEach((key) => {
    if (Array.isArray(errorList[key])) {
      errorList[key].forEach((val, index) => {
        if (index === 0) touched[key] = [];
        touched[key].push(getTouchedObj(val));
      });
    } else {
      touched[key] = true;
    }
  });

  return touched;
};

export const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  />
);