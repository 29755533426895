import React, { useEffect } from "react";
import SortableDivHeader from "../../common/sortableDivHeader";
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import { TriStateButton } from "../../common/triStateButton";
import { HcpTristate, Tooltips } from "../../../helper/constants";
import { isSupportUser } from "../../common/userRole";
import AccessibleAccordion from "../../common/AccessibleAccordion";
import Loading from "../../loading";

export const HCPTable = ({
  sort,
  order,
  setSorting,
  onStateChange,
  onAccordionElementChange,
  practices,
  isLoading,
  sortPractices,
  sortOrderForPractices,
  hcp = [],
}) => {
  useEffect(() => {
    // work around to get practices by API when accordion is open by default
    if (hcp?.length === 1) {
      onAccordionElementChange(hcp[0]);
    }
  }, [practices?.length]);
  const setSort = (sortField) => {
    setSorting(sortField);
  };
  const sortOrder = `${sort},${order}`;
  const triStateDisabled = isSupportUser();
  const accordionHeaderMarkup = (item) => (
    <div className="row" key={item.organizationId}>
      <div className="col-md-2">
        <div className="row">
          <div className="col-md-2">
            <span className="accordion__arrow org-accordion-arrow"></span>
          </div>
          <div className="col-md-2">{item.organizationId}</div>
        </div>
      </div>
      <div className="col-md-3">{item.name}</div>
      <div className="col-md-3">
        {`${!_.isNil(item.street) ? item.street : ""}  ${item.zipCode} ${item.city
          }`}
      </div>
      <div
        className="col-md-2"
        title={moment(item.registrationDate).format("L LTS")}
      >
        {moment(item.registrationDate).fromNow(true)}
      </div>
      <div className="col-md-1 p-0">
        <TriStateButton
          state={item.accountState}
          id={item.organizationId}
          onHcpStateChange={onStateChange}
          triStates={HcpTristate}
          tooltips={Tooltips}
          disabled={triStateDisabled}
        />
      </div>
      <div className="col-md-1 hcp-pull-right">
        {
          <Link
            data-qa="manage__edit-button"
            to={`/organization/${item.organizationId}`}
            data-tip
            data-for={`tooltip-edit-${item.organizationId}`}
          >
            <i className="fa fa-edit fa-fw hcp-management-edit-delete" />
          </Link>
        }
      </div>
    </div>
  );

  const accordionContent = (organizationId) => {
    const practiceData = practices?.get(organizationId);
    return !practiceData?.data ? (
      <Loading isLoading={isLoading} forFullViewport={false} />
    ) : (
      <div className="container-fluid">
        <div className="row mr-2">
          <SortableDivHeader
            className="col-3"
            name="signiaAccountNumber"
            onClick={() => sortPractices("wsaAccountNumber", organizationId)}
            value={"Signia Account Number"}
            sort={sortOrderForPractices}
          />
          <SortableDivHeader
            className="col-3"
            name="name"
            onClick={() => sortPractices("name", organizationId)}
            value={"Practice Name"}
            sort={sortOrderForPractices}
          />
          <div className="col-3">
            <b>City</b>
          </div>

          <div className="col-3">
            <b>Has Patients</b>
          </div>
        </div>
        <div
          className="mt-2"
          style={{ maxHeight: "240px", overflow: "clip scroll" }}
        >
          {practiceData?.data.map((practice) => (
            <div className="row" key={practice.id}>
              <div
                className={`col-3 ${practice?.isHighlighted ? "font-searched-org-practice" : ""
                  }`}
              >
                {practice.wsaAccountNumber}
              </div>
              <div
                className={`col-3 ${practice?.isHighlighted ? "font-searched-org-practice" : ""
                  }`}
              >
                {practice.name}
              </div>
              <div
                className={`col-3 ${practice?.isHighlighted ? "font-searched-org-practice" : ""
                  }`}
              >
                {practice.city}
              </div>
              <div
                className={`col-3 ${practice?.isHighlighted ? "font-searched-org-practice" : ""
                  }`}
              >{`${practice.hasPatients}`}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getAccordion = () => {
    if (hcp?.length > 1) {
      return hcp.map((item) => {
        return (
          <AccessibleAccordion
            key={item.organizationId}
            header={accordionHeaderMarkup(item)}
            content={accordionContent(item.organizationId)}
            onChange={() => {
              onAccordionElementChange(item);
            }}
            uuid={item.organizationId}
          />
        );
      });
    } else if (hcp?.length === 1) {
      return hcp.map((item) => {
        return (
          <AccessibleAccordion
            key={item.organizationId}
            header={accordionHeaderMarkup(item)}
            content={accordionContent(item.organizationId)}
            onChange={() => {
              onAccordionElementChange(item);
            }}
            uuid={item.organizationId}
            preExpandedUuid={[item.organizationId]}
          />
        );
      });
    }
  };
  return (
    <div className="container-fluid p-0">
      <hr />
      <div className="row p-2">
        <SortableDivHeader
          className="col-md-2"
          name="organizationId"
          onClick={() => setSort("organizationId")}
          value={"Organization ID"}
          sort={sortOrder}
        />
        <SortableDivHeader
          className="col-md-3"
          name="orgName"
          onClick={() => setSort("orgName")}
          value={"Organization Name"}
          sort={sortOrder}
        />
        <SortableDivHeader
          className="col-md-3"
          name="street"
          onClick={() => setSort("street")}
          value={"Address"}
          sort={sortOrder}
        />
        <SortableDivHeader
          className="col-md-2"
          name="createdAt"
          onClick={() => setSort("createdAt")}
          value={"Waiting Time"}
          sort={sortOrder}
        />
        <SortableDivHeader
          className="col-md-1"
          name="state"
          onClick={() => setSort("state")}
          value={"Status"}
          sort={sortOrder}
        />
        <div className="hcp-pull-right col-1">&nbsp;</div>
      </div>
      {getAccordion()}
    </div>
  );
};

export default HCPTable;
