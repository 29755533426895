
export async function getHttp(url, token) {
  return await fetch(url, {
    method: 'get',
    headers: generateHeader(token)
  }).then(result => {
    if (result.status === 200) {
      return result.json()
    }
  }).then(data => {
    return data;
  }).catch(error => {
    console.error("Error: ", error)
  })
}

function generateHeader(token) {
  const headers = {}

  headers.Accept = 'application/json'
  headers['Authorization'] = 'Bearer ' + token

  return new Headers(headers)
}