import { adalApiFetch } from "../auth/adalConfig"
import { getOptionsWithCorrelationContext } from '../helper/constants';


const getCountries = async (correlationContext="") => {
    let options = {
        method: "get",
        headers: {
          "Content-Type": "application/ json",
        },
      };
      
    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/countries', options)
        .then(async response => {
            if(response.status === 200){
               let json = await response.json()
               return {data: json, statusCode: 200}
            }
            return { statusCode: response.status }
        })
        .catch(err => {
            console.log(err)
        })
}

const saveCountry = async (countryCode, countryDetails, correlationContext="") => {
    let options ={
        method: "put",
        headers: { 'Content-Type': 'application/ json' },
        body: JSON.stringify(countryDetails)
    };
      
    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/countries/' + countryCode, options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

const deleteCountry = async (countryCode, correlationContext="") => {
    let options = {
        method: "delete",
        headers: { 'Content-Type': 'application/ json' }      
      };
      
    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/countries/' + countryCode, options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

const addCountry = async (countryDetails, correlationContext="") => {
    let options = {
        method: "post",
        headers: { 'Content-Type': 'application/ json' },
        body: JSON.stringify(countryDetails)
    };
      
    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/countries', options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

export { getCountries, saveCountry, deleteCountry, addCountry }
