//import { getCodeList } from 'country-list'
import { countries, continents } from 'countries-list'

const getCountryListWithNames = () => {

    let countryList = {}
    Object.keys(countries).forEach(c => {
        countryList[c.toUpperCase()] = countries[c].name
    })
    return countryList
}

const getContinentsWithName = () => {
    return continents
}

const getContinentsWithCountry = () => {
    let countryByContinent = {}
    Object.keys(continents).forEach(continent => {
        let data = []
        Object.keys(countries).forEach(countryCode => {
            if (continent === countries[countryCode].continent)
                data.push(countryCode)
        })
        countryByContinent[continent] = data
    })
    return countryByContinent
}

export { getCountryListWithNames, getContinentsWithName, getContinentsWithCountry }