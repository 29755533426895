import { AuthenticationContext, adalFetch } from "react-adal";
import { getConfiguration } from "../services/ManageConfig";
import ConfigProvider from "./configProvider";

let cfgProvider = new ConfigProvider();

export const getAdalConfig = async () => {
  const adalConfig = {};
  const frontEndConfig = await getConfiguration();
  adalConfig.clientId = frontEndConfig?.clientId;
  adalConfig.tenant = frontEndConfig?.tenantId;
  adalConfig.endpoints = {};
  adalConfig.endpoints.api = frontEndConfig?.apiResourceId;
  adalConfig.postLogoutRedirectUri = window.location.origin;
  adalConfig.redirectUri = window.location.origin;
  adalConfig.cacheLocation = "localStorage";
  cfgProvider.setConfig(adalConfig);
  return adalConfig;
};

export const authContext = async () => {
  if (cfgProvider.getConfig()) {
    return new AuthenticationContext(cfgProvider.getConfig());
  }
  const config = await getAdalConfig();
  return new AuthenticationContext(config);
};

export const adalApiFetch = async (fetch, url, options) => {
  const context = await authContext();
  const api = cfgProvider.getConfig().endpoints.api;
  return adalFetch(context, api, fetch, url, options);
};
