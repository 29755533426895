import React from "react";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import { Button } from "react-bootstrap";
import SuccessFailResult from "../../common/SuccessFailResult"
import { withRouter } from "../../../helper/withrouter";

export const MergeOrganizationsResult = ({ location, navigate }) => {

  const LabelConsts = {
    mergeOrgResult: 'Merge organizations result',
    orgId: 'Organization ID: ',
    orgMerge: 'Organization merge:',
    patientMerge: 'Patient merge:',
    practiceMerge: 'Practice merge:',
    autoReplyMerge: 'Autoreply merge:',
    shopEmployeeMerge: 'Shop employee merge:',
    goBack: 'Go back',
    errorInvalidOrgId: 'Error: Organization id is either not present or is already merged"',
    errorGeneric: 'Error: Something went wrong, transaction aborted',
    errorMergeFailed: 'Merge failed',
    orgMergeSuccessful: 'Organizations merged successfully'
  }

  const isOrgMergeSuccessful = (orgMergeResult) => {
    const { isOrgMergeSuccessful,
      isPatientMergeSuccessful,
      isPracticeMergeSuccessful,
      isAutoReplyMergeSuccessful,
      isShopEmployeeMergeSuccessful } = orgMergeResult

    return isOrgMergeSuccessful && isPatientMergeSuccessful &&
      isPracticeMergeSuccessful && isAutoReplyMergeSuccessful
      && isShopEmployeeMergeSuccessful
  }

  const getErrorMessageByErrorCode = (item) => {
    switch (item.errorCode) {
      case "INVALID_ORGANIZATION_ID":
        return LabelConsts.errorInvalidOrgId
      case "UNSUCCESSFUL_TRANSACTION":
        return LabelConsts.errorGeneric
      case null: {
        if (isOrgMergeSuccessful(item)) {
          return LabelConsts.orgMergeSuccessful
        }
        return LabelConsts.errorMergeFailed
      }
      default:
        return LabelConsts.errorMergeFailed
    }
  }

  const mergeResult = location && location.state && location.state.data;

  return (
    <div className="container-fluid row m-5">
      <div className="card col-md-8 offset-2">
        <div className="card-header">{LabelConsts.mergeOrgResult}</div>
        {mergeResult &&
          mergeResult.map((item, index) => (
            <div key={item.orgId} className="card-body">
              <div className={`card text-white ${isOrgMergeSuccessful(item) ? "bg-success" : "bg-danger"}`} >
                <div className="card-header">{LabelConsts.orgId} {item.orgId}</div>
                <div className="card-body bg-light text-dark">
                  <div className="row align-items-center">
                    <SuccessFailResult label={LabelConsts.orgMerge} classes='col-md' isSuccessful={item.isOrgMergeSuccessful} />
                    <SuccessFailResult label={LabelConsts.patientMerge} classes='col-md' isSuccessful={item.isPatientMergeSuccessful} />
                    <SuccessFailResult label={LabelConsts.practiceMerge} classes='col-md' isSuccessful={item.isPracticeMergeSuccessful} />
                    <SuccessFailResult label={LabelConsts.autoReplyMerge} classes='col-md' isSuccessful={item.isAutoReplyMergeSuccessful} />
                    <SuccessFailResult label={LabelConsts.shopEmployeeMerge} classes='col-md' isSuccessful={item.isShopEmployeeMergeSuccessful} />
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-md">
                      {getErrorMessageByErrorCode(item)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <Button
          className="col-md-2 offset-md-10 btn btn-secondary merge-organizations-btn-secondary"
          onClick={() => {
            navigate("/mergeOrganizations");
          }}
        >
          {LabelConsts.goBack}
        </Button>
        <br />
      </div>
    </div>
  );
};
export default withAuthorization(
  withRouter(MergeOrganizationsResult),
  PageAuthorizations.MergeOrganizationsResult.authorizedRoles
);
