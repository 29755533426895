import { adalApiFetch } from "../auth/adalConfig"
import { getOptionsWithCorrelationContext } from '../helper/constants';

const getAccountManagers = async (correlationContext = "") => {
  let options = {
    method: "get",
    headers: {
      "Content-Type": "application/ json",
    },
  };
  
  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, "/api/account-manager?page=0&size=1000", options)
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json._embedded.accountManagers, statusCode: 200 };
      }
      return { statusCode: response.status };
    })
    .catch((err) => {
      console.log(err);
    });
};

const getAccountManagerById = async ( id, correlationContext = "" ) => {
    let options = {
        method: "get",
        headers: {
          "Content-Type": "application/ json",
        },
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, `/api/account-manager/${id}`, options)
        .then(async response => {
            if(response.status === 200){
               let json = await response.json()
               return {data: json, statusCode: 200}
            }
            return { statusCode: response.status }
        })
        .catch(err => {
            console.log(err)
        })
}

const addAccountManager = async ( accountManagerDetails, correlationContext = "" ) => {
    let options = {
        method: "post",
        headers: { 'Content-Type': 'application/ json' },
        body: JSON.stringify(accountManagerDetails)
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/account-manager/', options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

const editAccountManager = async ( id, accountManagerDetails , correlationContext = "") => {
    let options = {
        method: "put",
        headers: { 'Content-Type': 'application/ json' },
        body: JSON.stringify(accountManagerDetails)
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, `/api/account-manager/${id}`, options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

const updateAccountManagerRole = async ( accountManagerRoleDetails, correlationContext = "" ) => {
    let options = {
        method: "put",
        headers: { 'Content-Type': 'application/ json' },
        body: JSON.stringify(accountManagerRoleDetails)
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);
    
    return adalApiFetch(fetch, `/api/account-manager/update-role`, options)
}

const deleteAccountManager = async ( id, correlationContext = "" ) => {
    let options = {
        method: "delete",
        headers: { 'Content-Type': 'application/ json' }
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, `/api/account-manager/${id}`, options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

const searchUserByEmail = async (searchText, correlationContext = "") => {
    let options = {
        method: "get",
        headers: { 'Content-Type': 'application/ json' }
      };
      
      options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, `/api/account-manager/userprinciple/${searchText}`, options)
        .then(async response => {
            let json = await response.json()
            return json
        })
        .catch(err => {
            console.log(err)
        })
}

export { getAccountManagers, getAccountManagerById, addAccountManager, editAccountManager, updateAccountManagerRole, deleteAccountManager, searchUserByEmail}
