import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { withRouter } from "./helper/withrouter"
import { NavMenu } from "../src/pages/NavMenu";
import Navigation from "../src/components/navigation";
import Layout from "../src/pages/Layout";
import { Link } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";
import "./scss/styles.scss";

class App extends Component {
  displayName = App.name;

  render() {
    const pathName = this.props.location.pathname;
    return (
      <Container fluid>
        <Row className="mb-3">
          <NavMenu />
        </Row>
        <Row className="mt-5 ml-5 pt-5 bread-crumbs">
          <i className="fa fa-home fa-lg" />
          &nbsp;
          <Link key="homepage" to="/">
            Home
          </Link>
        </Row>
        <Row>
          <Navigation activeLink={pathName} />
          <Layout />
        </Row>
      </Container>
    );
  }
}
export default withRouter(App);
