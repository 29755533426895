import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import InputField from "../../../formFields/InputField";
import { getCountryListWithNames } from "../../../helper/countryList";
import { Button } from "react-bootstrap";
import { getCountries } from "../../../services/ManageCountry";
import {
  getAccountManagerById,
  editAccountManager,
  updateAccountManagerRole,
} from "../../../services/ManageAccountManagers";
import Loading from "../../loading";
import CountryByContinentSelectField from "../../../formFields/CountryByContinentSelectField";
import _ from "lodash";
import { getUserUniqueName } from "../../../auth/adalFacade";
import { RadioButtonGroup } from '../../common/radioButtonGroup'
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants';
import { withRouter } from "../../../helper/withrouter";

let SupportId = getSupportId()

export const EditAccount = (props) => {
  const [countryListWithNames, setCountryListWithNames] = useState({});
  const [accountManager, setAccountManager] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [originalAccountDetail, setOriginalAccountDetail] = useState(false);
  const [isCountrySelectionDisabled, setIsCountrySelectionDisabled] = useState(false);
  const [disbaleTcRegistration, setDisbaleTcRegistration] = useState(false);
  const roleOption = ['AMBASSADOR', 'ADMIN', 'SUPPORT']
  const roleOptionLabels = [
    'User',
    'Admin',
    'Support'
  ]

  const countries = getCountryListWithNames();

  const fetchAccountManagerData = async () => {
    let countryList = [],
      accountManagerDetail = {};
    setIsLoading(true);
    const correlationContextCountry = CorrelationContext["ManageCountry_GetCountries"] + SupportId;
    countryList = await getCountries(correlationContextCountry);
    const correlationContextAccManager = CorrelationContext["ManageUsers_GetAccountManagerById"] + SupportId;
    accountManagerDetail = await getAccountManagerById(props.params.id, correlationContextAccManager);
    setIsLoading(false);
    setAccountManager(accountManagerDetail.data);
    setOriginalAccountDetail(accountManagerDetail.data);
    setCountryListWithNames(getCountriesWithName(countryList.data));
  };

  useEffect(() => {
    fetchAccountManagerData();
    window.scrollTo(0, 0);
  }, []);

  const getCountriesWithName = (countryList) => {
    let countryData = {};
    countryList.forEach((element) => {
      let data = Object.entries(countries);
      for (let [key, value] of data) {
        if (key === element.countryCode) {
          countryData[key] = value;
        }
      }
    });
    return countryData;
  };

  const checkAndUpdateAccountRole = async (
    role,
    userEmail,
    accountUrl
  ) => {
    if (originalAccountDetail.role != role) {
      const accountManagerRoleDetails = {
        role,
        userEmail,
        accountUrl,
      };
      try {
        const correlationContext = CorrelationContext["ManageUsers_UpdateAccountManager"] + SupportId;
        await updateAccountManagerRole(accountManagerRoleDetails, correlationContext);
        return true;
      }
      catch (error) {
        console.log("error", error);
        return false;
      }
    }
  };

  const checkAndUpdateAccountDetail = async (
    role,
    accountManagerDetails
  ) => {
    if (
      !_.isEqual(
        _.sortBy(accountManagerDetails.countries),
        _.sortBy(originalAccountDetail.countries)
      ) ||
      accountManagerDetails.tcRegistrationNotificationEnabled !==
      originalAccountDetail.tcRegistrationNotificationEnabled
    ) {
      await editAccountManager(props.params.id, accountManagerDetails)
      await checkAndUpdateAccountRole(
        role,
        accountManagerDetails.email,
        accountManagerDetails._links.accountManager.href || ""
      )
      setIsLoading(false);
      props.navigate(`/accounts`);
    } else {
      await checkAndUpdateAccountRole(
        role,
        accountManagerDetails.email,
        accountManagerDetails._links.accountManager.href || ""
      )
      setIsLoading(false);
      props.navigate(`/accounts`);
    }
  };

  const onSubmit = async (values, actions) => {
    const accountManagerDetails = {
      ...accountManager,
      email: values.email,
      role: values.role,
      tcRegistrationNotificationEnabled: values.tcRegistrationNotificationEnabled,
      countries: Object.keys(values).filter((data) => {
        return (
          data !== "isAccountAdmin" &&
          data !== "tcRegistrationNotificationEnabled" &&
          values[data] === true
        );
      }),
    };
    if (accountManagerDetails["countries"].length === 0) {
      actions.setFieldError("countries", "Please select country");
    } else {
      setIsLoading(true);
      return await checkAndUpdateAccountDetail(
        values.role,
        accountManagerDetails
      );
    }
  };

  const onCancel = () => {
    props.navigate(`/accounts`);
  };

  const onSelect = (e, setFieldValue, values) => {
    if (e.target.value == roleOption[2]) {
      handleCountrySelection(true, values, true, setFieldValue)
      handleTcRegistrationNotificationSelection(true, false, setFieldValue)
    }
    else if (e.target.value == roleOption[1]) {
      handleCountrySelection(false, values, true, setFieldValue)
      handleTcRegistrationNotificationSelection(false, false, setFieldValue)
    }
    else {
      handleCountrySelection(false, values, false, setFieldValue)
      handleTcRegistrationNotificationSelection(false, true, setFieldValue)
    }
  }

  const handleTcRegistrationNotificationSelection = (disabled, isChecked, setFieldValue) => {
    setDisbaleTcRegistration(disabled)
    setFieldValue('tcRegistrationNotificationEnabled', isChecked)
  }

  const handleCountrySelection = (disableCountrySelection, values, checkAllCountries, setFieldValue) => {
    setIsCountrySelectionDisabled(disableCountrySelection)
    Object.keys(values).filter((data) => {
      if (data !== "tcRegistrationNotificationEnabled" && (values[data] === true || values[data] === false)) {
        setFieldValue(data, checkAllCountries)
      }
    })
  }

  const prepareAccountData = () => {
    let selectedCountry = {};
    Object.keys(countryListWithNames).forEach((countryCode) => {
      if (
        accountManager &&
        accountManager.countries &&
        accountManager.countries.includes(countryCode)
      )
        selectedCountry[countryCode] = true;
      else selectedCountry[countryCode] = false;
    });
    return {
      ...accountManager,
      countries: countryListWithNames,
      ...selectedCountry,
      role: accountManager.role
    };
  };
  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="container-fluid">
          <div className="row m-5">
            <Formik
              onSubmit={(values, actions) => onSubmit(values, actions)}
              enableReinitialize={true}
              initialValues={prepareAccountData()}
            >
              {({ touched, values }) => {
                const isSupportRole = values.role == roleOption[2]
                return (
                  <Form className="container-fluid">
                    <div>
                      <div>
                        <div>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="firstName"
                                label="First Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"first name"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                name="lastName"
                                label="Last Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"last name"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="email"
                                label="E-mail"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"email"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                disabled={getUserUniqueName() === accountManager.email}
                                name="role"
                                component={RadioButtonGroup}
                                header={'Role'}
                                options={roleOption}
                                labelArray={roleOptionLabels}
                                onSelect={onSelect}
                              />

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 form-group">
                              <Field
                                name="tcRegistrationNotificationEnabled"
                                key="tcRegistrationNotificationEnabled"
                                component={({ field }) => {
                                  return (
                                    <div className="admin_role_form">
                                      <input
                                        {...field}
                                        type="checkbox"
                                        className="checkbox-default"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          field.onChange(e);
                                          field.onBlur(e);
                                        }}
                                        defaultChecked={field.value}
                                        disabled={disbaleTcRegistration || isSupportRole}
                                      />
                                      <label className="ml-3">
                                        <b>Enable Registration Notification</b>
                                      </label>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name="countries"
                                component={CountryByContinentSelectField}
                                label="Select Markets"
                                disabled={isCountrySelectionDisabled || isSupportRole}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 offset-md-8 form-group">
                        <button
                          className="btn btn-secondary btn-block"
                          type="button"
                          onClick={onCancel}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2 form-group">
                        <Button
                          className="primary-button-edit-country"
                          variant="success"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuthorization(
  withRouter(EditAccount),
  PageAuthorizations.EditAccount.authorizedRoles
);
