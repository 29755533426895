import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { withAuthorization } from '../../higher-order-components/AuthorizationHOC'
import { PageAuthorizations } from '../../../auth/pageAuthorization'
import SuccessFailResult from '../../common/SuccessFailResult'
import { withRouter } from '../../../helper/withrouter'

export function DeleteOrganizationResult({ location, navigate }) {

  const LabelConsts = {
    deleteOrgResult: 'Delete organization result',
    orgId: 'Organization ID:',
    chatRecordsDeleted: 'Chat records deleted:',
    patientRecordsDeleted: 'Patient records deleted:',
    employeeRecordsDeleted: 'Employee records deleted:',
    practiceRecordsDeleted: 'Practice records deleted:',
    orgRecordsDeleted: 'Organization records deleted:',
    goBack: 'Go back',
    errorOrgIdNotFound: 'Error: Organization id is either not present or is already deleted.',
    errorInvalidOrgState: `Error: Organization can only be deleted if it's in REJECTED state.`,
    orgDeletedSuccessfully: 'Organization deleted successfully.',
    errorGeneric: 'Something went wrong. Organization deletion failed.'
  }


  const [isOrgDeleteSuccessful, setIsOrgDeleteSuccessful] = useState(false)

  const deleteResult = location && location.state && location.state.data
  const {
    orgId,
    isChatRecordsDeletionSuccessful,
    isPatientRecordsDeletionSuccessful,
    isEmployeeRecordsDeletionSuccessful,
    isPracticeRecordsDeletionSuccessful,
    isOrganizationRecordsDeletionSuccessful,
    errorCode
  } = deleteResult

  const getErrorMessageByErrorCode = () => {
    switch (errorCode) {
      case "ORGANIZATION_ID_NOT_FOUND":
        return LabelConsts.errorOrgIdNotFound
      case "INVALID_ORGANIZATION_STATE":
        return LabelConsts.errorInvalidOrgState
      case null: {
        if (isOrgDeleteSuccessful) {
          return LabelConsts.orgDeletedSuccessfully
        }
        return LabelConsts.errorGeneric
      }
      default:
        return LabelConsts.errorGeneric
    }
  }

  useEffect(() => {
    const result = deleteResult && isChatRecordsDeletionSuccessful && isPatientRecordsDeletionSuccessful &&
      isEmployeeRecordsDeletionSuccessful && isPracticeRecordsDeletionSuccessful
      && isOrganizationRecordsDeletionSuccessful && errorCode === null

    setIsOrgDeleteSuccessful(result)
  }, [])

  return (
    <div className="container-fluid row m-5">
      <div className="card col-8 offset-2">
        <div className="card-header font-weight-bold">{LabelConsts.deleteOrgResult}</div>
        {deleteResult &&
          <div className="card-body">
            <div className={`card text-white ${isOrgDeleteSuccessful ? "bg-success" : "bg-danger"}`}>
              <div className="card-header">{LabelConsts.orgId} {orgId}</div>
              <div className="card-body bg-light text-dark">
                <div className="row align-items-center p-2">
                  <SuccessFailResult label={LabelConsts.chatRecordsDeleted} classes="col" isSuccessful={isChatRecordsDeletionSuccessful} />
                  <SuccessFailResult label={LabelConsts.patientRecordsDeleted} classes='col' isSuccessful={isPatientRecordsDeletionSuccessful} />
                </div>
                <div className="row align-items-center p-2">
                  <SuccessFailResult label={LabelConsts.employeeRecordsDeleted} classes='col' isSuccessful={isEmployeeRecordsDeletionSuccessful} />
                  <SuccessFailResult label={LabelConsts.practiceRecordsDeleted} classes='col' isSuccessful={isPracticeRecordsDeletionSuccessful} />
                </div>
                <div className="row align-items-center p-2">
                  <SuccessFailResult label={LabelConsts.orgRecordsDeleted} classes='col' isSuccessful={isOrganizationRecordsDeletionSuccessful} />
                </div>
                <br />
                <div className="row align-items-center">
                  <div className="col ml-2">
                    {getErrorMessageByErrorCode()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className='row mt-2 mr-3'>
          <Button
            className="col-2 offset-10 btn btn-secondary merge-organizations-btn-secondary"
            onClick={() => { navigate("/organization") }} >
            {LabelConsts.goBack}
          </Button>
        </div>
        <br />
      </div>
    </div>
  )
}

export default withAuthorization(
  withRouter(DeleteOrganizationResult),
  PageAuthorizations.DeleteOrganizationResult.authorizedRoles
)