import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/errorBoundary";
import Home from "./pages/Home";
import SearchAccount from "./components/management/searchAccount/";
import { NotAuthorizedPage } from "./pages/NotAuthorizedPage";
import AccountManagement from "./components/management/account";
import EditAccount from "./components/management/account/editAccount"
import CreateAccount from "./components/management/account/createAccount"
import CountryManagement from "./components/management/country";
import Organization from "./components/management/organization";
import EditCountry from "./components/management/country/editCountry";
import CreateCountry from "./components/management/country/createCountry";
import ImportTranslations from "./components/management/importTranslations";
import EditOrganization from "./components/management/organization/editOrganization";
import DeleteOrganizationResult from './components/management/organization/deleteOrganizationResult'
import MergeOrganizations from "./components/management/mergeOrganizations";
import MergeOrganizationsResult from "./components/management/mergeOrganizations/result";
import WhatsNew from "./pages/WhatsNew";

const ApplicationRoutes = () => (
  <ErrorBoundary>
    <Routes>
      <Route exact path="/" Component={Home} />
      <Route exact path="/reset-password" Component={SearchAccount} />
      <Route exact path="/organization" Component={Organization} />
      <Route exact path="/country/add" Component={CreateCountry} />
      <Route exact path="/country/:countrycode" Component={EditCountry} />
      <Route exact path="/country" Component={CountryManagement} />
      <Route exact path="/accounts/add" Component={CreateAccount} />
      <Route exact path="/accounts" Component={AccountManagement} />
      <Route exact path="/accounts/:id" Component={EditAccount} />
      <Route exact path="/not-authorized" Component={NotAuthorizedPage} />
      <Route exact path='/translations' Component={ImportTranslations} />
      <Route exact path='/organization/:id' Component={EditOrganization} />
      <Route exact path='/organization/:id/result' Component={DeleteOrganizationResult} />
      <Route exact path='/mergeOrganizations' Component={MergeOrganizations} />
      <Route exact path='/mergeOrganizations/result' Component={MergeOrganizationsResult} />
      <Route exact path='/whatsnew' Component={WhatsNew} />
    </Routes>
  </ErrorBoundary>
);

export default ApplicationRoutes;
