import React from 'react';
import SelectField from './SelectField';

export const ShowEntrySelectField = (props) => {
    return (
        <div className={`d-flex flex-row align-items-center ${props?.className}`}>
            <div>Show</div>
            <SelectField {...props} />
            <div>entries</div>
        </div>
    )
}

export default ShowEntrySelectField
