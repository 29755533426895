import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal"

export const Content = ({ onClose, confirm, popup }) => {
  const payload = popup ? popup.payload : null
  return (
    payload &&
    <div data-qa="confirm-delete" className="popup--confirm-delete">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12">
            <div className="icon">
              <i className="fa fa-fw fa-trash fa-2x" />
            </div>
            <h3 className="popup_title" data-qa="confirm-delete-title">
              {payload.title}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div data-qa="confirm-delete-message">
              {payload.message}
            </div>
          </div>
        </div>
      </div>
      <div className="row button-area">
        <div className="col-6">
          <button
            data-qa="confirm-delete-cancel-button"
            className="btn btn-secondary btn-block"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            data-qa="confirm-delete-ok-button"
            className="btn btn-primary btn-block"
            onClick={confirm}
            type="button"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  )
}

export class ConfirmDeletionPopup extends Component {
  render() {
    const {
      onClose,
      popup,
      isOpen,
      onDelete,
    } = this.props
    const isOpened = popup && isOpen
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpened}
        onHide={() => onClose(false)}>
        <Modal.Body className="error-modal">
          <Content onClose={onClose} popup={popup} confirm={onDelete} />
        </Modal.Body>
      </Modal>
    )
  }
}

export default ConfirmDeletionPopup
