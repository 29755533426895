import { adalApiFetch } from "../auth/adalConfig";
import { getOptionsWithCorrelationContext } from "../helper/constants";

export default class ManageHABrands {
  static async getAllHABrands(correlationContext = "") {
    let options = {
      method: "get",
      headers: { "Content-Type": "application/json" },
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, `/api/ha-brands`, options)
      .then(async (response) => await response.json())
      .catch((err) => console.log(err));
  }

  static async saveActiveHABrands(id, haBrandIds, correlationContext = "") {
    let options = {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(haBrandIds),
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);
    try {
      return await adalApiFetch(
        fetch,
        `/api/accounts/${id}/ha-brands`,
        options
      );
    } catch (err) {
      console.log(err);
    }
  }
}
