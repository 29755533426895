import React, { useState, useEffect } from 'react'
import Loading from "../../loading";
import { Button } from "react-bootstrap";
import ErrorResponse from "../../common/errorResponse"
import { getCountryCallingCodes } from "../../../services/ManageCountryCallingCode";
import CountryCodeTable from './countryCallingCodeTable'
import ManageCountryCallingCodePopup from "../../../popups/manageCountryCallingCodePopup";
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'

function CountryCallingcode() {
  const [countryCallingCodes, setCountryCallingCodes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(200)
  const [modalShow, setModalShow] = useState(false)
  const [editedRow, setEditedRow] = useState()
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false)

  const DefaultCountryCodeConst = 'Default'

  async function fetchCountryCallingCode() {
    setIsLoading(true)
    const countryCallingCodeData = await getCountryCallingCodes();
    processApiResult(countryCallingCodeData)
    setIsLoading(false)
  }

  const handleEditedRow = (rowData) => {
    setEditedRow(rowData)
    setModalShow(true)
  }

  const handleModalClose = () => {
    setEditedRow(null)
    setModalShow(false)
  }

  const processApiResult = countryCodeData => {
    if (countryCodeData.statusCode === 200) {
      const defaultConfig = countryCodeData?.data.filter(x => x.countryCallingCode === DefaultCountryCodeConst)
      const remainingConfigs = _.orderBy(countryCodeData?.data.filter(x => x.countryCallingCode !== DefaultCountryCodeConst), ['lastModified'], ['desc'])

      const sortedArray = []
      sortedArray.push(...defaultConfig, ...remainingConfigs)

      setCountryCallingCodes(sortedArray)
    }
    else {
      setStatusCode(countryCodeData.statusCode)
    }
  }

  useEffect(() => {
    fetchCountryCallingCode()
  }, [])

  return (
    statusCode === 200 ? 
    <div className='row country-calling-code'>
      <div className="col-md-4 pl-0"><h2><b>Sender ID Configuration</b></h2></div>
      <div className="col-md-2 offset-md-6">
        <Button
          className="primary-button-edit-country"
          variant="success"
          onClick={() => setModalShow(true)}>
          Create
        </Button>
      </div>
      {!countryCallingCodes
        ?
        <Loading isLoading={isLoading} />
        :
        <CountryCodeTable editedRow={handleEditedRow}
          countryCodes={countryCallingCodes}
          setcountrycodes={setCountryCallingCodes}
          setIsLoading={setIsLoading}
          fetchCountryCallingCode={fetchCountryCallingCode} />
      }
      <ManageCountryCallingCodePopup editedRow={editedRow}
        modalShow={modalShow}
        handleModalClose={handleModalClose}
        countryCallingCodes={countryCallingCodes}
        setIsOpenErrorPopup={setIsOpenErrorPopup}
        fetchCountryCallingCode={fetchCountryCallingCode}
      />
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpenErrorPopup}
        onHide={() => setIsOpenErrorPopup(false)}>
        <Modal.Header style={{ paddingLeft: "0.5rem" }}>
          <h4>Error Occured</h4>
        </Modal.Header>
        <Modal.Body className="error-modal">
          <div className='row pl-2'>
            <p className='mb-3 '>The given country prefix already exists.</p>
            <button className='btn btn-danger offset-sm-4'
              style={{ width: "25%" }}
              onClick={() => { setIsOpenErrorPopup(false) }}>OK</button>
          </div>
        </Modal.Body>
      </Modal>
    </div> : <ErrorResponse statusCode={statusCode} />
  )
}

export default CountryCallingcode
