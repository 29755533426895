import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import * as Yup from "yup";
import CheckboxField from "../formFields/CheckboxField";
import InputField from "../formFields/InputField";
import Loading from "../components/loading";
import { Formik, Form, Field } from "formik";
import { CorrelationContext } from '../helper/constants'
import { addCountryCallingCode, updateCountryCallingCode } from "../services/ManageCountryCallingCode";
import { getSupportId } from '../helper/ApplicationInsightsHelper'

let SupportId = getSupportId()
const countryPrefixRegex = /^((Default)|(\+(\d{1,3})))$/
function ManageCountryCallingCodePopup(props) {

  const [isLoading, setIsLoading] = useState(false)

  const {
    editedRow,
    countryCallingCodes,
    handleModalClose,
    setIsOpenErrorPopup,
    modalShow,
    fetchCountryCallingCode } = props

  const handleSubmit = (values) => {

    setIsLoading(true)
    const request = extractFormDataFromValues(values)
    const correlationContext = CorrelationContext["ManageCountry_AddCountry"] + SupportId

    if (editedRow) {
      updateCountryCallingCode(request, correlationContext)
        .then((response) => {

          fetchCountryCallingCode();
        })
        .catch((response) => {
          console.log("error", response)
        })
        .finally(() => {
          setIsLoading(false)
          handleModalClose()
        })
    }
    else {
      const itemExists = countryCallingCodes.filter(x => x.countryCallingCode === request.countryCallingCode)

      if (itemExists !== null && itemExists !== undefined && itemExists.length > 0) {
        setIsLoading(false)
        handleModalClose()
        setIsOpenErrorPopup(true)
        return
      }

      addCountryCallingCode(request, correlationContext)
        .then((response) => {
          fetchCountryCallingCode();
        })
        .catch((response) => {
          console.log("error", response)
        })
        .finally(() => {
          setIsLoading(false)
          handleModalClose()
        })
    }
  }

  function extractFormDataFromValues(values) {
    const countryCodeDetails = {};
    countryCodeDetails["countryCallingCode"] = values.countryCallingCode;
    countryCodeDetails["senderNumber"] = values.senderNumber;
    countryCodeDetails["isSenderConfigEnabled"] = values.isSenderConfigEnabled;
    countryCodeDetails["isCallingCodeBlocked"] = values.isCallingCodeBlocked;
    countryCodeDetails["countryName"] = values.countryName;
    countryCodeDetails["description"] = values.description;

    return countryCodeDetails
  }

  const countryCodeValidationSchema = Yup.object().shape({
    isSenderConfigEnabled: Yup.boolean(),
    isCallingCodeBlocked: Yup.boolean(),
    countryCallingCode: Yup.string().matches(countryPrefixRegex, "Country prefix length should be between 1-3 and must start with + sign.")
      .required("Country prefix can't be empty."),
    senderNumber: Yup.string().test({
      message: "Invalid sender ID. Numeric numbers must start with + symbol and length should be between 3-16. Alphanumeric numbers length must be between 3-11 and should not contain symbols.",
      test: function (value) {
        return validateSenderNumber(value, this.parent.isSenderConfigEnabled)
      },
    })
  });

  const validateSenderNumber = (number, enableSenderNumberConfig) => {
    const regexForNumericWithPulsSymbol = /^\+(\d{2,15})$/;
    const regForAlphaNumeric = /^([a-zA-Z0-9]{3,11})$/;
    if (!number) {
      return enableSenderNumberConfig ? false : true;
    }

    if (number.charAt(0) === '+') {
      if (validateRegex(number, regexForNumericWithPulsSymbol)) {
        return true;
      }
    }
    else if (validateRegex(number, regForAlphaNumeric)) {
      return true;
    }

    return false;
  }

  const validateRegex = (validateStr, pattern) => validateStr.match(pattern)

  const initialData = {
    countryCallingCode: editedRow ? editedRow.countryCallingCode : "",
    senderNumber: editedRow ? editedRow.senderNumber : "",
    isSenderConfigEnabled: editedRow ? editedRow.isSenderConfigEnabled : false,
    isCallingCodeBlocked: editedRow ? editedRow.isCallingCodeBlocked : false,
    description: editedRow ? editedRow.description : "",
    countryName: editedRow ? editedRow.countryName : "",
  };

  const disableConfigCheckBox = initialData?.countryCallingCode?.toLowerCase() === 'default' ? true : false;

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Modal className="country-code-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => handleModalClose()}>

          <Modal.Body className="country-code-modal-body">
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={countryCodeValidationSchema}
              initialValues={initialData}
            >
              {() => {
                return (
                  <Form>
                    <div>
                      <h5 className='mb-3'>Add new sender ID configuration</h5>

                      <div className="row" >
                        <div className="col-md-4 form-group">
                          <Field disabled={!!initialData.countryCallingCode}
                            name="countryCallingCode"
                            label="Country Prefix"
                            type="text"
                            className="input-wrapper"
                            component={InputField}
                          />
                        </div>

                        <div className="col-md-4 form-group">
                          <Field
                            name="countryName"
                            label="Country Name"
                            type="text"
                            className="input-wrapper"
                            component={InputField}
                          />
                        </div>

                        <div className="col-md-4 form-group">
                          <Field
                            name="senderNumber"
                            label="Sender ID"
                            type="text"
                            className="input-wrapper"
                            component={InputField}
                          />
                        </div>

                        <div className="col-md-6" >
                          <div className="col-md-12 form-group control-label">
                            <Field
                              name="isSenderConfigEnabled"
                              component={CheckboxField}
                              label={"Enable Sender ID Config"}
                              isDisabled={disableConfigCheckBox}
                              options={{
                                description: {
                                  key: "Enable Sender ID Config",
                                },
                              }}
                            />
                          </div>

                          <div className="col-md-12 form-group control-label">
                            <Field
                              name="isCallingCodeBlocked"
                              component={CheckboxField}
                              label={"Block Sending SMS"}
                              isDisabled={disableConfigCheckBox}
                              options={{
                                description: {
                                  key: "Block Sending SMS",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 form-group">
                          <label>
                            Description
                            <Field
                              name="description"
                              label="Description"
                              className="input-wrapper"
                              component="textarea"
                            />
                          </label>
                        </div>

                        <div className="col-md-3 ">
                          <button
                            className="btn btn-secondary btn-block"
                            type="button"
                            onClick={handleModalClose}
                          > Cancel
                          </button>
                        </div>

                        <div className="col-md-3">
                          <Button
                            className="primary-button-edit-country"
                            variant="success"
                            type="submit"
                          > Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </Modal >
      )
      }
    </>
  )
}

export default ManageCountryCallingCodePopup;
