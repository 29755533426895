import {Roles} from "./pageAuthorization"

export function getUserRoles() {
  let roles = window.authContext.getCachedUser().profile.roles;
  let userRolesOrDefaultRole = roles || [Roles.RegularUser];
  return userRolesOrDefaultRole
}

export function getUserUniqueName() {
  return window.authContext.getCachedUser().profile.unique_name;
}