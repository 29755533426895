import React from 'react'
import SortableTableHeader from '../../common/sortableTableHeader'

const CountryTable = ({ sort, setSorting, countries = [], countryListWithName=[] }) => {

  const setSort = sortField => () => {
    setSorting(sortField)
  }

  const optionYes = (
    <span>
      <i className="fa fa-check fa-md" /> Yes
    </span>
  );
  const optionNo = (
    <span>
      <i className="fa fa-times fa-md" /> No
    </span>
  );

    return (
      <table className="table table--users mt-5">
        <thead>
          <tr>
            <SortableTableHeader
              className="col-md-2"
              name="countryCode"
              onClick={setSort('countryCode')}
              value={'Country Code'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-4"
              name="countryName"
              onClick={setSort('countryName')}
              value={'Country Name'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-2"
              name="enableHcpRegistration"
              onClick={setSort('enableHcpRegistration')}
              value={'Enable HCP Registration'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-4"
              name="enableAccountManagerInvitation"
              onClick={setSort('enableAccountManagerInvitation')}
              value={'Enable Account Manager Invitation'}
              sort={sort}
            />
          </tr>
        </thead>
        <tbody>
        {Object.keys(countries).map((countryId) => {
              const country = countries[countryId];
              return (
                <tr key={country.countryCode}>
                  <td>
                    <a href={"/country/" + country.countryCode}>
                      {country.countryCode}
                    </a>
                  </td>
                  <td>
                    {countryListWithName[country.countryCode.toUpperCase()]}
                  </td>
                  <td>
                    {country.enableHcpRegistration ? optionYes : optionNo}
                  </td>
                  <td>
                    {country.enableAccountManagerInvitation
                      ? optionYes
                      : optionNo}
                  </td>
                </tr>
              );
            })}
          </tbody>
      </table>
    )
}

export default CountryTable
