import { appInsights } from "../ApplicationInsight";
import { AppInsightCloudRoleName } from "./constants";

export const getSupportId = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export function FlushEventsToAppInsight(message) {
  appInsights.trackEvent(
    { name: message },
    { ServiceName: AppInsightCloudRoleName }
  );
}
