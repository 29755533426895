import { getHttp } from "./HttpService"

const token = '552fbb197f4971add81b843bbf4e641923439bb3a656ad339dcc8f2971eea1be'
const projectId = '3835bd7eea64e7114aadce4d4203c818'
const cacheKey = "releaseNotes"
const RELEASE_NOTES_REGEXP = /^releasenotes-(\d+)-/
const PREFIX = "releasenotes"

// Page refresh clears release notes cache.
window.addEventListener("unload", () => {
    sessionStorage.removeItem(cacheKey)
})

// Don't specify count if you want all release notes.
export async function getReleaseNotes(count) {
    const releaseNotesFromCache = sessionStorage.getItem(cacheKey)
    if (!releaseNotesFromCache || releaseNotesFromCache.length === 0) {
        let rawReleaseNotes = await requestAllReleaseNotes()
        var result = sortAndArrangeReleaseNotes(rawReleaseNotes)
        sessionStorage.setItem(cacheKey, JSON.stringify(result))
        return count ? result.slice(0, count) : result
    }
    else {
        const cachedReleaseNotesObj = JSON.parse(releaseNotesFromCache)
        return count ? cachedReleaseNotesObj.slice(0, count) : cachedReleaseNotesObj
    }
}

async function requestAllReleaseNotes() {
    return await getHttp(`https://api.phraseapp.com/api/v2/projects/${projectId}/translations?per_page=100`, token)
}

function sortAndArrangeReleaseNotes(rawReleaseNotes) {
    var result = []
    var reducedReleaseNotes = reduceReleaseNotes(rawReleaseNotes)

    Object.keys(reducedReleaseNotes).filter(
        (key) =>
            RELEASE_NOTES_REGEXP.test(key) && key.indexOf("content") !== -1
    )
        .sort(
            (a, b) =>
                RELEASE_NOTES_REGEXP.exec(b)[1] -
                RELEASE_NOTES_REGEXP.exec(a)[1]
        ).forEach(item => {
            const order = Number(RELEASE_NOTES_REGEXP.exec(item)[1])
            result.push({
                title: reducedReleaseNotes[`${PREFIX}-${order}-title`],
                content: reducedReleaseNotes[`${PREFIX}-${order}-content`]
            })
        })

    return result;
}

function reduceReleaseNotes(rawReleaseNotes) {
    var result = []
    rawReleaseNotes.forEach(releaseNote => {
        result[releaseNote.key.name] = releaseNote.content
    })
    return result
}