import React from "react";
import { Formik, Form, Field } from "formik";
import { Card } from "react-bootstrap";
import SelectField from "./SelectField";
import { HcpManagementFilter } from "../helper/constants";
import SearchBar from "./SearchBar";

const SearchBySelectField = ({ handleSearch, searchTerm, handleSearchText, onFilterChange, hcpFilter, isSearchActive, setIsSearchActive }) => {

  return (
    <Card className="filter-rounded">
      <div className="row p-2">
        <div className="col-6">
          <Formik
            initialValues={{hcpFilter}}
            enableReinitialize={true}
          >
            <Form>
              <Field
                name="hcpFilter"
                component={SelectField}
                label={"Filter For Search"}
                options={Object.entries(HcpManagementFilter).map((value) => ({
                  value: value[1],
                  label: value[0],
                }))}
                onSelect={onFilterChange}
              />
            </Form>
          </Formik>
        </div>
        <div className="col-6 align-self-center mt-3 justify-content-end">
          <SearchBar handleSearch={handleSearch} hcpFilter={hcpFilter} searchTerm={searchTerm} handleSearchText={handleSearchText} isSearchActive={isSearchActive} setIsSearchActive={setIsSearchActive}/>
        </div>
      </div>
      </Card>
  );
};

export default SearchBySelectField;
