import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "../../../helper/withrouter";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import SelectField from "../../../formFields/SelectField";
import CheckboxField from "../../../formFields/CheckboxField";
import InputField from "../../../formFields/InputField";
import { getCountryListWithNames } from "../../../helper/countryList";
import { Button } from "react-bootstrap";
import { addCountry, getCountries } from "../../../services/ManageCountry";
import Loading from "../../loading";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'

let SupportId = getSupportId()

export const CreateCountry = (props) => {
  const [countryListWithNames, setCountryListWithNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const countries = getCountryListWithNames()

  const fetchCountries = async () => {
    let countryList = [];
    setIsLoading(true);
    countryList = await getCountries();
    setIsLoading(false);
    setCountryListWithNames(getCountriesWithName(countryList.data));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const countryValidationSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select countryName").strict(),
  });

  const getCountriesWithName = (countryList) => {
    let countryData = {};
    let data = Object.entries(countries);
    for (let [key, value] of data) {
      if (
        countryList.filter((countryData) => countryData.countryCode === key)
          .length == 0
      ) {
        countryData[key] = value;
      }
    }
    return countryData;
  };

  const onCancel = () => {
    props.navigate(`/country`);
  };

  const onSubmit = (values, setFieldError) => {
    const countryDetails = {};
    countryDetails["countryCode"] = values.countryCode;
    countryDetails["accountNumberValidation"] = values.accountNumberValidation;
    countryDetails["zipCodeValidation"] = values.zipCodeValidation;
    countryDetails["enableHcpRegistration"] = values.enableHcpRegistration;
    countryDetails["enableAccountManagerInvitation"] =
      values.enableAccountManagerInvitation;
    setIsLoading(true);
    const correlationContext = CorrelationContext["ManageCountry_AddCountry"] + SupportId;
    return addCountry(countryDetails, correlationContext)
      .then((response) => {
        setIsLoading(false);
        props.history.push({ pathname: `/country` });
      })
      .catch((response) => {
        console.log("error", response);
      });
  };

  const initialData = {
    accountNumberValidation: "",
    countryCode: "",
    enableAccountManagerInvitation: false,
    enableHcpRegistration: false,
    zipCodeValidation: "",
  };
  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="container-fluid">
          <div className="row m-5">
            <Formik
              onSubmit={(values, actions) =>
                onSubmit(values, actions.setFieldError)
              }
              validationSchema={countryValidationSchema}
              enableReinitialize={true}
              initialValues={initialData}
            >
              {() => {
                return (
                  <Form className="container-fluid">
                    <div>
                      <div>
                        <div>
                          <h2 data-qa="title">Add New Country</h2>

                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="countryCode"
                                component={SelectField}
                                label={"Country name"}
                                labelClassName="required"
                                options={Object.entries(
                                  countryListWithNames
                                ).map((x) => ({
                                  value: x[0],
                                  label: x[1],
                                }))}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="accountNumberValidation"
                                label="Account Number Validation Regular Expression"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={".*"}
                                autoComplete="username"
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                name="zipCodeValidation"
                                label="Zip Code Validation Regular Expression"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={".*"}
                                autoComplete="username"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-group control-label">
                              Options
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-group control-label">
                              <Field
                                name="enableHcpRegistration"
                                component={CheckboxField}
                                label={"Enable Hcp registration"}
                                options={{
                                  description: {
                                    key: "Enable Hcp registration",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-md-6 form-group control-label">
                              <Field
                                name="enableAccountManagerInvitation"
                                component={CheckboxField}
                                label={"Enable account manager invitation"}
                                options={{
                                  description: {
                                    key: "Enable account manager invitation",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2 offset-md-8 form-group">
                          <button
                            className="btn btn-secondary btn-block"
                            type="button"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-2 form-group">
                          <Button
                            className="primary-button-edit-country"
                            variant="success"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuthorization(
  withRouter(CreateCountry),
  PageAuthorizations.CreateCountry.authorizedRoles
);
