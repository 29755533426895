import React, { Component } from 'react';
import ApplicationRoutes from '../routes';

export default class Layout extends Component {
    displayName = Layout.name

    render() {
        return <ApplicationRoutes />
    }
}
