import React from "react";
import { errorResponse } from '../../helper/constants'

const ErrorResponse = ({ statusCode }) => {
  const message = errorResponse[statusCode]
  if (message)
    return <div className="container-fluid m-5 text-center"><h2>{message}</h2></div>;
  return <div className="container-fluid m-5 text-center"><h2>{errorResponse[500]}</h2></div>;
};

export default ErrorResponse;
