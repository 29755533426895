import React from 'react'

const SuccessFailResult = ({ label, isSuccessful, classes }) => {
  return (
    <div className={classes}>
      {label}
      {isSuccessful ? (
        <i className="fa fa-check success-icon" aria-hidden="true" />
      ) : (
        <i className="fa fa-close unsuccessful-icon" aria-hidden="true" />
      )}
    </div>
  )
}

export default SuccessFailResult
