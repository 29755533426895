import React from 'react'

export const RadioButtonGroup = ({
    header,
    field,
    field: { value, name },
    labelArray = [],
    options = [],
    form: { setFieldValue, values },
    onSelect,
    disabled
  }) =>
    <div className="form-group" data-qa={name}>
      <label className="roleRadioButton">
        {header}
      </label>
      <div className="form-inline">
        {labelArray &&
          labelArray.map((label, index) => {
            return (
              <div key={index} className="form-check mb-2 mr-sm-2">
                <input
                  id={index + name}
                  checked={value == options[index]}
                  type="radio"
                  name={name}
                  value={options[index]}
                  onChange={e => {
                    field.onChange(e)
                    onSelect(e, setFieldValue, values)
                  }}
                  className="form-check-input"
                  disabled={disabled}
                />
                <label
                  htmlFor={index + name}
                  className={`form-check-label radio-group--custom ${disabled ? 'disabled' : ''}`}
                  key={index}
                >
                  {label}
                </label>
              </div>
            )
          })}
      </div>
    </div>