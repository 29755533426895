const getConfiguration = async () => {
  try {
    let response = await fetch("/api/ClientConfigurations");
    let data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
};

export { getConfiguration };
