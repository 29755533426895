import React from "react";

const SortableDivHeader = ({ name, value, onClick, className, sort = "" }) => {
  const byField = sort.split(",")[0];
  const order = sort.split(",")[1];
  const sortIndicatorClass = () => {
    let commonClass = "fa fa-fw fa-sort";
    if (byField === name) {
      if (order === "asc") {
        return `${commonClass}-up`;
      }
      return `${commonClass}-down`;
    }
    return commonClass;
  };

  return (
    <div className={className} onClick={onClick}>
      <b>{value}</b>
      <i className={sortIndicatorClass()} />
    </div>
  );
};

export default SortableDivHeader;
