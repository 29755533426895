import React from "react";
import { ErrorMessage, getIn } from "formik";
import Select from "react-select";

export const getLabelForGroupedOptions = (options, value) => {
  let a;
  options.map((lessonGroup) => {
    lessonGroup.options.some((option) => {
      if (option.value === value) {
        a = option;
        return true;
      }
    });
  });
  return a.label;
};

export const SelectField = ({
  field,
  label,
  labelClassName,
  hint,
  form: { touched, errors, setFieldValue, values, setFieldTouched },
  onSelect = () => { },
  disabled,
  options,
  centered,
  placeholder,
  clearable,
  searchable = true,
  grouped,
  selectClassName,
  ...props
}) => {
  const defaultPlaceholder = placeholder || `Select..`;
  const labelClasses = (labelClassName || "").split(" ");
  labelClasses.push("control-label");
  const selectStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 100,
    }),
    control: (styles) => ({
      ...styles,
      border: "1px solid #9d9c9c",
      boxShadow: "none",
      cursor: "pointer",
      backgroundColor: disabled ? "#f2f2f2" : "white",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? "#cfcfcf"
            : isFocused
              ? "#e3e3e3"
              : null,
        color: isDisabled ? "#ccc" : "#3a3a3a",
        cursor: isDisabled ? "not-allowed" : "pointer",
        textAlign: centered ? "center" : "left",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#cfcfcf" : "#e3e3e3"),
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      alignItems: centered ? "center" : "default",
      justifyContent: centered ? "center" : "normal",
    }),
    groupHeading: (styles) => ({
      ...styles,
      backgroundColor: "#cfcfcf",
      color: "black",
      fontSize: "14px",
      padding: "8px 12px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#333333",
    }),
  };
  return (
    <div
      className={`form-group ${selectClassName} ${getIn(touched, field.name) && getIn(errors, field.name)
          ? "has-error"
          : ""
        }`}
      data-testid="select-field"
    >
      {label && (
        <label htmlFor={props.name} className={labelClasses.join(" ")}>
          {label}
        </label>
      )}
      <Select
        {...props}
        id={field.name}
        name={field.name}
        value={
          options.length > 0 && field.value
            ? grouped
              ? {
                value: field.value,
                label: getLabelForGroupedOptions(options, field.value),
              }
              : {
                value: field.value,
                label: options.find((option) => option.value === field.value)
                  ? options.find((option) => option.value === field.value)
                    .label
                  : defaultPlaceholder,
              }
            : ""
        }
        onChange={(e) => {
          setFieldValue(field.name, e ? e.value : null);
          onSelect(e ? e.value : null, setFieldValue, values);
        }}
        onBlur={() => setFieldTouched(field.name)}
        className={`basic-single`}
        classNamePrefix="select"
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        options={options}
        placeholder={defaultPlaceholder}
        styles={selectStyles}
        menuPlacement="auto"
      />
      <ErrorMessage
        name={field.name}
        render={(msg) => <div className="help-block">{msg}</div>}
      />
      {hint && <span className="help-block">{hint}</span>}
    </div>
  );
};

export default SelectField;
