class ConfigProvider {
  myConfig = null;

  setConfig(config) {
    this.myConfig = config;
  }

  getConfig() {
    return this.myConfig;
  }
}
export default ConfigProvider;
