import { adalApiFetch } from "../auth/adalConfig";

export const importTranslationsForFaqService = async () => {
  return adalApiFetch(fetch, "/api/translations/faq", {
    method: "get",
    headers: { "Content-Type": "application/ json" },
  })
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const importTranslationsForTcbe = async () => {
  return adalApiFetch(fetch, "/api/translations/tcbe", {
    method: "get",
    headers: { "Content-Type": "application/ json" },
  })
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const importTranslationsForDailySatisfactions = async () => {
  return adalApiFetch(fetch, "/api/translations/dailySatisfactions", {
    method: "get",
    headers: { "Content-Type": "application/ json" },
  })
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const importTranslationsForTelecareLessons = async () => {
  return adalApiFetch(fetch, "/api/translations/telecareLessons", {
    method: "get",
    headers: { "Content-Type": "application/ json" },
  })
    .then(async (response) => {
      if (response.status === 200) {
        let json = await response.json();
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const importAllTranslations = async () => {
  const urls = [
    "/api/translations/faq",
    "/api/translations/tcbe",
    "/api/translations/dailySatisfactions",
    "/api/translations/telecareLessons"
  ];
  try {
    var data = await Promise.all(
      urls.map((url) =>
        adalApiFetch(fetch, url, {
          method: "get",
          headers: { "Content-Type": "application/ json" },
        }).then((response) => {
          if (response.status === 200) {
            let json = response.json();
            return { data: json, statusCode: 200 }
          }
          return { statusCode: response.status }
        })
      )
    );
    let status = data.filter(x => x !== true).length > 0 ? false : true
    if (!status) {
      return { data: true, statusCode: 200 };
    } else {
      return { statusCode: 400 };
    }
  } catch (error) {
    console.log(error);
  }
};
