'use strict';
import fetchIntercept from 'fetch-intercept';

/* global __APIURL__ */
const baseURL = process.env.REACT_APP_SERVICE_PORTAL_API_BASE_URL || 'https://serviceportalapi.cloudapi-dev.sivantos.com'
export const registerFetchIntercept = () => fetchIntercept.register({
    request: function (url, config) {
        url = url.startsWith('/api/') ? baseURL + url : url;
        return [url, config];
    },
});