import React from 'react'
import { omit } from 'lodash'
import queryString from 'query-string'
import { withRouter } from "../helper/withrouter";
import { HcpManagementFilter } from "../helper/constants";
import { StyledInput } from '../styledComponents/Input'
import { SearchWrapper, ActionIcon } from '../styledComponents/fields/SearchBar'

export const SearchBar = ({ handleSearch, searchTerm, hcpFilter, handleSearchText, isSearchActive, setIsSearchActive, navigate, location }) => {

    const handleChange = event => {
        handleSearchText(event.target.value)

    }

    const clearSearchTerm = () => {
        setIsSearchActive(false)
        handleSearchText('')
        if (searchTerm.length > 0) {
            const query = omit(location.query, 'search')
            navigate({
                pathname: location.pathname,
                query
            })
            window.location.reload()
        }
    }

    const onClickActionItem = () => {
        isSearchActive ? clearSearchTerm() : fireSearch()
    }

    const fireSearch = () => {
        if (searchTerm.length > 0) {
            setIsSearchActive(true)
            const parsed = queryString.parse(location.search);
            parsed.search = searchTerm
            parsed.filter = hcpFilter
            location.search = queryString.stringify(parsed)
            navigate(location)
            handleSearch()
        }
    }

    const onKeyPressInputSearch = e => {
        if (e.key === 'Enter' && searchTerm.length > 0) {
            setIsSearchActive(true)
            fireSearch()
        }
    }

    const placeholderForSearch = Object.entries(HcpManagementFilter).find(value => value.includes(hcpFilter))[0];

    const placeholderForSearchByOrgId = "Search for Organization Ids by ',' separated...";

    return (
        <SearchWrapper>
            <StyledInput
                type="text"
                placeholder={placeholderForSearch === "Organization Id" ? placeholderForSearchByOrgId : `Search for ${Object.entries(HcpManagementFilter).find(value => value.includes(hcpFilter))[0]}...`}
                value={searchTerm}
                onChange={handleChange}
                onKeyPress={onKeyPressInputSearch}
            />
            <ActionIcon onClick={() => onClickActionItem()} />
        </SearchWrapper>
    )
}

export default withRouter(SearchBar)
