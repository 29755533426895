import React, { Component } from "react";

export class MergeOrganizationsPopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup-inner">
          <div data-qa="popup--success" className="popupNoConnection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="icon">
                    <i className="fas fa-fw fa-check fa-2x" />
                  </div>
                  <h1 data-qa="popup__title">{this.props.title}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p data-qa="popup__message">{this.props.message}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <div className="row">
                    <div className="col-6 ml-auto mr-auto">
                      <button
                        data-qa="popup__button"
                        className="btn btn-block btn-secondary"
                        onClick={this.props.onCancel}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6 ml-auto mr-auto">
                      <button
                        variant="success"
                        data-qa="popup__button"
                        className="btn btn-block btn-primary primary-button-edit-country"
                        onClick={this.props.onOk}
                        type="submit"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MergeOrganizationsPopup;
