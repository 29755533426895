import React, { useEffect } from 'react';
import * as AdalFacade from "../../auth/adalFacade";
import { useNavigate } from "react-router-dom";
export function withAuthorization(WrappedComponent, allowedRoles) {
  function ComponentWithAuthorization(props) {
    let navigate = useNavigate();
    useEffect(() => {
      let UserHasRole = r => AdalFacade.getUserRoles().includes(r);
      let userHasAtLeastOneRole = allowedRoles.some(UserHasRole);

      if (!userHasAtLeastOneRole) {
        handleUnauthorizedRole();
      }
    }, [])

    const handleUnauthorizedRole = () => {
      navigate('/not-authorized');
    }
    return (
      <WrappedComponent
        {...props}
      />
    );
  }
  return ComponentWithAuthorization;
}
