import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import SelectField from '../../../formFields/SelectField'
import CheckboxField from '../../../formFields/CheckboxField'
import InputField from '../../../formFields/InputField'
import { getCountryListWithNames } from "../../../helper/countryList";
import { Button } from 'react-bootstrap';
import { saveCountry, getCountries, deleteCountry } from '../../../services/ManageCountry'
import Loading from '../../loading'
import ConfirmDeletionPopup from '../../../popups/confirmDeletionPopup'
import { DELETE_POPUP, SHOW_POPUP, getOverlayUpdateData } from '../../../popups/OverlayUpdateData'
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'
import { withRouter } from '../../../helper/withrouter';

let SupportId = getSupportId()
export const EditCountry = (props) => {

	const [country, setCountry] = useState([])
	const [countryListWithNames, setCountryListWithNames] = useState([])
	const [countryList, setCountryList] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isOpenDeletionPopup, setIsOpenDeletionPopup] = useState(false)

	const countries = getCountryListWithNames()


	const fetchCountries = async () => {
		let countryList = []
		setIsLoading(true)
		countryList = await getCountries()
		setIsLoading(false)
		setCountryList(countryList.data)
		setCountryListWithNames(getCountriesWithName(countryList.data))
		setCountry(countryList.data.filter(x => x.countryCode === props.params.countrycode)[0])
	}
	useEffect(() => {
		fetchCountries()
	}, [])

	const popup = getOverlayUpdateData(SHOW_POPUP, {
		type: DELETE_POPUP,
		payload: {
			title: 'Delete Country',
			message: 'Are you sure to delete this country ?'
		}
	})

	const getCountriesWithName = (countryList) => {

		let countryData = {}
		countryList.forEach(element => {
			let data = Object.entries(countries)
			for (let [key, value] of data) {
				if (key === element.countryCode) {
					countryData[key] = value
				}
			}
		})
		return countryData
	}

	const onSubmit = (values, setFieldError) => {
		const countryDetails = {}
		countryDetails['countryCode'] = values.countryCode
		countryDetails['accountNumberValidation'] = values.accountNumberValidation
		countryDetails['zipCodeValidation'] = values.zipCodeValidation
		countryDetails['enableHcpRegistration'] = values.enableHcpRegistration
		countryDetails['enableAccountManagerInvitation'] = values.enableAccountManagerInvitation
		const correlationContext = CorrelationContext["ManageCountry_SaveCountry"] + SupportId;
		setIsLoading(true)
		return saveCountry(values.countryCode, countryDetails, correlationContext)
			.then(response => {
				setIsLoading(false)
				props.navigate(`/country`)
			})
			.catch(response => {
				console.log('error', response)
			})
	}

	const onChange = (countryCode) => {
		let countryDetails = countryList.filter(x => x.countryCode === countryCode)[0];
		if (!countryDetails.accountNumberValidation) countryDetails.accountNumberValidation = ''
		if (!countryDetails.zipCodeValidation) countryDetails.zipCodeValidation = ''
		setCountry(countryDetails)
	}

	const onCancel = () => {
		props.navigate('/country')
	}

	const removeCountry = async (countryCode) => {
		const correlationContext = CorrelationContext["ManageCountry_DeleteCountry"] + SupportId;
		await deleteCountry(countryCode, correlationContext)
		props.navigate(`/country`)
	}

	const CreateRemovePatientButton = () => {
		return (
			<Button variant="danger" className="btn btn-secondary btn-block" onClick={() => setIsOpenDeletionPopup(!isOpenDeletionPopup)}>
				Delete
			</Button>
		)
	}

	const popupCloseHandler = () => {
		setIsOpenDeletionPopup(false)
	}

	const removeButton = CreateRemovePatientButton()

	return (<>
		{isLoading ? <Loading isLoading={isLoading} /> : <div className="container-fluid">
			<div className="row m-5">
				<Formik
					onSubmit={(values, actions) => onSubmit(values, actions.setFieldError)}
					enableReinitialize={true}
					initialValues={country}
				>
					{() => {
						return (
							<Form className='container-fluid'>
								<div>
									<div>
										<div>
											<h2 data-qa="title">
												Edit country
											</h2>

											<div className="row">
												<div className="col-md-6 form-group">
													<Field
														name="countryCode"
														component={SelectField}
														label={'Country name'}
														labelClassName="required"
														options={Object.entries(countryListWithNames).map(x => ({
															value: x[0],
															label: x[1]
														}))}
														onSelect={onChange}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 form-group">
													<Field
														name="accountNumberValidation"
														label="Account Number Validation Regular Expression"
														type="text"
														className="input-wrapper"
														component={InputField}
														placeholder={".*"}
														autoComplete="username"
													/>
												</div>
												<div className="col-md-6 form-group">
													<Field
														name="zipCodeValidation"
														label="Zip Code Validation Regular Expression"
														type="text"
														className="input-wrapper"
														component={InputField}
														placeholder={".*"}
														autoComplete="username"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 form-group control-label">
													Options
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 form-group control-label">
													<Field
														name="enableHcpRegistration"
														component={CheckboxField}
														label={'Enable Hcp registration'}
														options={
															{
																description: {
																	key: 'Enable Hcp registration'
																}
															}
														}
													/>
												</div>
												<div className="col-md-6 form-group control-label">
													<Field
														name="enableAccountManagerInvitation"
														component={CheckboxField}
														label={'Enable account manager invitation'}
														options={
															{
																description: {
																	key: 'Enable account manager invitation'
																}
															}
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-2 form-group">
											<ConfirmDeletionPopup
												openByClickOn={removeButton}
												onDelete={() => removeCountry(country.countryCode)}
												onClose={() => popupCloseHandler()}
												popup={popup}
												isOpen={isOpenDeletionPopup}
											/>
										</div>
										<div className="col-md-2 offset-md-6 form-group">
											<button className="btn btn-secondary btn-block" type="button" onClick={onCancel}>Cancel</button>
										</div>
										<div className="col-md-2 form-group">
											<Button
												className="primary-button-edit-country"
												variant="success"
												type="submit">Save</Button>
										</div>

									</div>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>}
	</>
	)
}

export default withAuthorization(withRouter(EditCountry), PageAuthorizations.EditCountry.authorizedRoles)