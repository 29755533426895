import React from 'react'
import AccordionElement from './common/AccordionElement'

export function ReleaseNotesContent(props) {
  const { releaseNotes } = props  
  let i = 0
  return ( 
    <React.Fragment>
      <h2 data-qa="title">
        Read what's new!
      </h2>
      {releaseNotes && releaseNotes.map((item) => {       
        return (
          <AccordionElement
            key={i++}                  
            question={item.title}
            answer={item.content}
            allowMarkdown={true}
          />
          )}
      )}
    </React.Fragment>
  )
}