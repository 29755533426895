import React, { useState, useEffect } from 'react'
import Loading from "../../loading";
import { getCountryListWithNames } from "../../../helper/countryList";
import CountryTable from "./countryTable"
import ErrorResponse from "../../common/errorResponse"
import { Button } from "react-bootstrap";
import { orderBy } from 'lodash'
import { getCountries } from "../../../services/ManageCountry";

function Country() {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(200)
  const [sort, setSort] = useState('countryCode asc')
  const countryListWithName = getCountryListWithNames()

  useEffect(() => {
    async function fetchCountry() {
      setIsLoading(true)
      const countryData = await getCountries();
      processApiResult(countryData)
      setIsLoading(false)
    }
    fetchCountry();
  }, []);

  const processApiResult = countryData => {
    if (countryData.statusCode === 200) {
      addCountryName(countryData.data)
    }
    else {
      setStatusCode(countryData.statusCode)

    }
  }

  const addCountryName = countryData => {
    const countriesWithName = countryData.map(country => {
      let countryName = countryListWithName[country.countryCode]
      return { ...country, countryName }
    })
    setCountries(countriesWithName)
  }

  const performSorting = (sortField, order) => {
    const sortedCountry = orderBy(countries, [sortField], [order])
    setCountries(sortedCountry)
  }

  const setSorting = sortField => {
    const prevOrder = sort.split(',')[1] || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'
    const newSort = [sortField, order].join(',')
    setSort(newSort)
    performSorting(sortField, order)
  }

  if (statusCode !== 200)
    return (<ErrorResponse statusCode={statusCode} />)

  return (
    <div className='row'>
      <div className="col-md-2 pl-0"><h2><b>Countries</b></h2></div>
      <div className="col-md-2 offset-md-6">
        <Button
          className="primary-button-edit-country"
          variant="success"
          href="/country/add">
          Create Country
        </Button>
      </div>
      {countries && countries.length === 0 ?
        <Loading isLoading={isLoading} />
        : <CountryTable countries={countries} countryListWithName={countryListWithName} sort={sort} setSorting={setSorting} />}
    </div>
  )
}

export default Country
