import React, { Component } from "react";
import Modal from "react-bootstrap/Modal"

export const Content = ({ confirm, popup }) => {
  const payload = popup ? popup.payload : null
  const icon = popup?.payload?.icon ? popup.payload.icon : "fa-check"
  return (
    payload &&
    <div data-qa="popup--error">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 error-Message">
            <div className="icon">
              <i className={`fas fa-fw ${icon} fa-2x`} />
            </div>
            <h1 data-qa="popup__title">{payload.title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 error-Message">
            <p data-qa="popup__message">{payload.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="row">
              <div className="col-4 ml-auto mr-auto">
                <button
                  data-qa="popup__button"
                  className="btn btn-block btn-secondary"
                  onClick={confirm}
                  type="submit"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export class ErrorPopup extends Component {
  render() {
    const {
      openByClickOn,
      onClose,
      popup,
      isOpen,
      onOpen,
      onConfirm,
      id,
      ...restProps
    } = this.props
    const isOpened = popup && isOpen
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpened}
        onHide={() => onClose(false)}>
        <Modal.Body className="error-modal">
          <Content {...restProps} popup={popup} confirm={onConfirm} />
        </Modal.Body>
      </Modal>
    )
  }
}

export default ErrorPopup;
