import { adalApiFetch } from "../auth/adalConfig"
import { getOptionsWithCorrelationContext } from '../helper/constants';

const countryCallingCodeUrl = "/api/CountryCallingCodes"

const getCountryCallingCodes = async (correlationContext = "") => {
  let options = {
    method: "get",
    headers: {
      "Content-Type": "application/ json",
    },
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, countryCallingCodeUrl, options)
    .then(async response => {
      if (response.status === 200) {
        let json = await response.json()
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch(err => {
      console.log(err)
    })
}

const addCountryCallingCode = async (countryCodeDetails, correlationContext = "") => {
  let options = {
    method: "post",
    headers: { 'Content-Type': 'application/ json' },
    body: JSON.stringify(countryCodeDetails)
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, countryCallingCodeUrl, options)// change
    .then(async response => {
      let json = await response.json()
      return json
    })
    .catch(err => {
      console.log(err)
    })
}

const updateCountryCallingCode = async (countryCodeDetails, correlationContext = "") => {

  let options = {
    method: "put",
    headers: { 'Content-Type': 'application/ json' },
    body: JSON.stringify(countryCodeDetails)
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, countryCallingCodeUrl, options)
    .then(async response => {
      let json = await response.json()
      return json
    })
    .catch(err => {
      console.log(err)
    })
}

const deleteCountryCallingCode = async (countryCallingcode, correlationContext = "") => {
  let options = {
    method: "delete",
    headers: { 'Content-Type': 'application/ json' }
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, countryCallingCodeUrl + '/' + countryCallingcode, options)
    .then(async response => {
      return Promise.resolve(response)
    })
    .catch(err => {
      console.log(err)
    })
}

export { getCountryCallingCodes, addCountryCallingCode, updateCountryCallingCode, deleteCountryCallingCode }