import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'isValidZipCode', function(countries, countryCode, message) {
    const msg = message || "Enter valid zip code"
    return this.test('isValidZipCodeTest', msg, function(value) {
      if (value) {
        const country = countries.find(c => c.countryCode === countryCode)
        const zipCodeRegex = (country && country.zipCodeValidation) || '.*'
        const zipRegex = new RegExp(zipCodeRegex)
        return zipRegex.test(value)
      }
    })
  })