import React, { useState } from "react";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  yupToFormErrors,
  validateYupSchema,
} from "formik";
import InputField from "../../../formFields/InputField";
import TextFieldArray from "../../../formFields/TextFieldArray";
import { Button } from "react-bootstrap";
import { mergeOrganizations } from "../../../services/MergeOrganizations";
import * as Yup from "yup";
import { MergeOrganizationsPopup } from "../../../popups/mergeOrganizationsPopup";
import { maxSizeOfTextboxComponent, mergeOrganizationTimeout, CorrelationContext } from "../../../helper/constants";
import Loading from '../../loading'
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { withRouter } from "../../../helper/withrouter";

let SupportId = getSupportId()

export const MergeOrganizations = ({ navigate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [togglePopUp, setTogglePopUp] = useState(false);

  const onCancel = () => {
    setTogglePopUp(false);
  };

  const onSubmit = async (values, actions) => {
    setTogglePopUp(true);
  };

  const validationSchema = Yup.object().shape({
    primaryOrg: Yup.string()
      .required("Please enter main organization ID")
      .strict()
      .test("is-not-a-number", "Organization ID should be a number", (value) => {
        return !isNaN(value) && !(value + "").match(/^\d*\.{1}\d*$/)
      }),
    secondaryOrg: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Please enter secondary organization ID")
          .strict()
          .test(
            "primary-id-to-secondary-ids-match",
            "Main organization ID can't be same as secondary organization ID",
            function (value) {
              const primaryOrgValue = this.options.context.primaryOrg;
              return value !== primaryOrgValue;
            }
          )
          .test(
            "secondary-id-to-secondary-ids-match",
            "Duplicate value found in secondary organization ids",
            function (value) {
              const { secondaryOrg } = this.options.context;
              const secondaryOrgValues = Array.from(
                secondaryOrg,
                (_) => _.name
              );
              const countOfDuplicateItems = secondaryOrgValues.filter(
                (_) => _ === value
              ).length;
              if (countOfDuplicateItems > 1) {
                return false;
              }
              return true;
            }
          )
          .test("is-not-a-number", "Organization ID should be a number", (value) => {
            return !isNaN(value) && !(value + "").match(/^\d*\.{1}\d*$/)
          }),
      })
    ),
  });

  return (
    isLoading ? <Loading isLoading={isLoading} maxTimeout={mergeOrganizationTimeout} /> : <div className="container-fluid m-5">
      <div className="col pl-0">
        <h2>
          <b>Merge Organizations</b>
        </h2>
      </div>
      <Formik
        onSubmit={async (values, actions) => onSubmit(values, actions)}
        initialValues={{
          primaryOrg: "",
          secondaryOrg: [{ name: "" }],
        }}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema, true, value);
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
      >
        {(formikProps) => {
          const mergeOrgHandler = () => {
            const { values, validateForm } = formikProps;
            validateForm(values);
          };
          const onMergeOrganizations = async () => {
            const { values } = formikProps;
            setIsLoading(true);
            const secondaryOrgIds = Array.from(
              values.secondaryOrg,
              (_) => _.name
            );
            const correlationContext = CorrelationContext["MergeOrg_MergeOrganizations"] + SupportId;
            const response = await mergeOrganizations({
              MainOrgId: values.primaryOrg,
              SecondaryOrgIds: secondaryOrgIds,
            }, correlationContext);
            setIsLoading(false);
            setTogglePopUp(false);
            navigate(`/mergeOrganizations/result`, {
              state: response,
            });
          };

          const getMessage = () => {
            const { primaryOrg, secondaryOrg } = formikProps.values;
            const commaSeparatedSecondaryOrgs = Array.from(
              secondaryOrg,
              (_) => _.name
            ).join(", ");
            const message = (
              <span>
                <span>
                  Organization ID(s) {commaSeparatedSecondaryOrgs} will be
                  merged into main organization ID {primaryOrg}.
                </span>
                <br />
                <span>
                  This process cannot be undone and can take some time.
                </span>
                <br /> <br />
                <span><b>Are you sure to merge?</b> </span>
              </span>
            );
            return message;
          };
          return (
            <Form>
              {togglePopUp ? (
                <MergeOrganizationsPopup
                  title={"Merge Organization"}
                  message={getMessage()}
                  onCancel={onCancel}
                  onOk={onMergeOrganizations}
                />
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="primaryOrg"
                    label="Main Organization"
                    type="text"
                    className="input-wrapper"
                    component={InputField}
                    placeholder={"Organization ID"}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FieldArray
                    name="secondaryOrg"
                    render={(arrayHelpers) => (
                      <TextFieldArray
                        {...arrayHelpers}
                        isReadOnly={isLoading}
                        label="Organization to be merged"
                        className="input-wrapper "
                        placeholder="Organization ID"
                        maxSize={maxSizeOfTextboxComponent}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 offset-md-3">
                  {
                    <Button
                      className="btn btn-secondary btn-block primary-button-edit-country"
                      variant="success"
                      type="submit"
                      onClick={mergeOrgHandler}
                    >
                      Merge
                      {isLoading && (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </Button>
                  }
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withAuthorization(
  withRouter(MergeOrganizations),
  PageAuthorizations.MergeOrganizations.authorizedRoles
);
