import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import SelectField from "../../../formFields/SelectField";
import MultiSelectField from "../../../formFields/MultiSelectField";
import InputField from "../../../formFields/InputField";
import { getCountryListWithNames } from "../../../helper/countryList";
import { Button } from "react-bootstrap";
import { getCountries } from "../../../services/ManageCountry";
import { getHCPById, editHcp, editHcpState, deleteOrganization } from "../../../services/ManageHCP";
import Loading from "../../loading";
import * as Yup from "yup";
import ConfirmationPopup from "../../../popups/confirmationPopup";
import {
  DELETE_POPUP,
  SHOW_POPUP,
  getOverlayUpdateData,
} from "../../../popups/OverlayUpdateData";
import { HcpTristate, HcpStatus, CorrelationContext } from "../../../helper/constants";
import { isSupportUser, isAdminUser } from "../../common/userRole";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import ManageHABrands from "../../../services/ManageHABrands";
import { withRouter } from "../../../helper/withrouter";
let SupportId = getSupportId()

const labelStateStyle = {
  ACTIVE: "label-outline-success",
  REGISTRATION_REJECTED: "label-outline-danger",
  PENDING: "label-outline-warning",
};

const ActionType = {
  DELETE: 'delete',
  ACCEPT: 'accept',
  REJECT: 'reject'
}

export const EditHCP = ({ params, navigate }) => {
  const [country, setCountry] = useState([]);
  const [countryListWithNames, setCountryListWithNames] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [org, setOrgDetails] = useState({});
  const [isOpenHcpStatePopup, setIsOpenHcpStatePopup] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [allHABrands, setAllHABrands] = useState([]);
  const [currentActionType, setCurrentActionType] = useState('');
  const countries = getCountryListWithNames();

  const fetchCountries = async () => {
    let allCountries = [];
    allCountries = await getCountries();
    return allCountries.data;
  };

  const fetchHCPDetails = async () => {
    const correlationContextFetchHcp = CorrelationContext["ManageHcp_GetHcpById"] + SupportId;
    const hcpById = await getHCPById(params.id, correlationContextFetchHcp);
    return hcpById.data;
  };

  const fetchAllHABrands = async () => {
    const correlationContextID = CorrelationContext["ManageOrg_GetAllHABrands"] + SupportId;
    return await ManageHABrands.getAllHABrands(correlationContextID);
  }

  const fetchAllData = async () => {
    setIsLoading(true);
    const [allCountries, orgDetails, allAvailableHABrands] = await Promise.all(
      [fetchCountries(), fetchHCPDetails(), fetchAllHABrands()]
    );
    setCountryList(allCountries);
    setCountryListWithNames(getCountriesWithName(allCountries));
    setOrgDetails(orgDetails);
    allCountries &&
      orgDetails &&
      setCountry(
        allCountries.filter(
          (x) => x.countryCode === orgDetails.countryCode
        )[0]
      );
    setAllHABrands(allAvailableHABrands);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllData();
    window.scrollTo(0, 0);
  }, []);

  const getCountriesWithName = (allCountries) => {
    let countryData = {};
    allCountries.forEach((element) => {
      let data = Object.entries(countries);
      for (let [key, value] of data) {
        if (key === element.countryCode) {
          countryData[key] = value;
        }
      }
    });
    return countryData;
  };

  const prepareHcpEditPayload = (values) => {
    const accountDetails = {
      admin: {},
      org: {},
      state: "",
    };
    const { state, orgEdit, admin } = values;
    accountDetails.org["id"] = parseInt(params.id);
    accountDetails.org["name"] = org.name;
    accountDetails.org["street"] = values.street;
    accountDetails.org["zipCode"] = values.zipCode;
    accountDetails.org["city"] = values.city;
    accountDetails.org["phone"] = values.phone;
    accountDetails.org["email"] = values.email;
    accountDetails.org["language"] = values.language;
    accountDetails.org["timeZone"] = org.timeZone;
    accountDetails.org["website"] = org.website;
    accountDetails.org["countryCode"] = values.countryCode;

    accountDetails.admin["firstName"] = values.admin.firstName;
    accountDetails.admin["lastName"] = values.admin.lastName;
    accountDetails.admin["username"] = values.admin.email;
    accountDetails.admin["email"] = values.admin.email;
    accountDetails.admin["phone"] = values.admin.phone;
    accountDetails.admin["id"] = values.admin.id;


    accountDetails.state = values.accountState;
    return accountDetails;
  };

  const onSubmit = async (values, { setFieldError, setFieldTouched }, saveMode) => {
    const { activeHABrands } = values;
    const hcpDetails = prepareHcpEditPayload(values);
    setIsBusy(true);
    const correlationContextEditHcp = CorrelationContext["ManageHcp_EditHcp"] + SupportId;

    try {
      const [saveOrganizationResponse, editHcpResponse] = await Promise.all(
        [
          saveActiveHABrands(activeHABrands),
          editHcp(
            params.id,
            hcpDetails,
            correlationContextEditHcp
          ),
        ]
      );
      setIsBusy(false);
      if (
        saveOrganizationResponse.status === 200 &&
        editHcpResponse.statusCode === 204
      ) {
        if (saveMode) {
          navigate(-1);
        }
        return true;
      } else if (
        editHcpResponse.error_code === "ACCOUNT_MANAGER_COUNTRY_ACCEPTANCE"
      ) {
        setFieldError("countryCode", editHcpResponse.description);
        return false;
      } else if (
        editHcpResponse.error_code === "EMAIL_ALREADY_EXISTS" ||
        editHcpResponse.error_code === "INVALID_USERNAME"
      ) {
        setFieldError("email", editHcpResponse.description);
        return false;
      } else if (saveOrganizationResponse.status !== 200) {
        setFieldError("activeSetsQueryName", "Configuration save failed");
        return false;
      } else {
        return false;
      }
    } catch (error) {
      console.log("error", error);
    }

  };

  const saveActiveHABrands = async (haBrands) => {
    const orgID = parseInt(params.id);
    const haBrandIds = haBrands?.map(x => x.value) ?? [];
    const correlationContext = CorrelationContext["ManageOrg_SaveActiveHABrands"] + SupportId;

    return ManageHABrands.saveActiveHABrands(orgID, haBrandIds, correlationContext);
  }

  const onChange = (countryCode) => {
    let countryDetails = countryList.filter(
      (x) => x.countryCode === countryCode
    )[0];
    if (!countryDetails.accountNumberValidation)
      countryDetails.accountNumberValidation = "";
    if (!countryDetails.zipCodeValidation)
      countryDetails.zipCodeValidation = "";
    setCountry(countryDetails);
  };

  const onCancel = () => {
    navigate(-1)
  };

  const editHCPValidationSchema = () =>
    Yup.object().shape({
      admin: Yup.object().shape({
        firstName: Yup.string().required("Please enter first name").strict(),
        lastName: Yup.string().required("Please enter last name").strict(),
        email: Yup.string()
          .required("Please enter email id")
          .strict()
          .email("Please enter a valid email"),
      })
    });

  const rejectPopup = getOverlayUpdateData(SHOW_POPUP, {
    type: DELETE_POPUP,
    payload: {
      title: "Reject Organization",
      message: "Do you want to reject? Your changes will also be saved.",
    },
  });

  const acceptPopup = getOverlayUpdateData(SHOW_POPUP, {
    type: DELETE_POPUP,
    payload: {
      title: "Accept Organization",
      message: "Do you want to accept? Your changes will also be saved.",
    },
  });

  const deletePopup = getOverlayUpdateData(SHOW_POPUP, {
    type: DELETE_POPUP,
    payload: {
      title: "Delete Organization",
      message: "Do you want to delete? This is irreversible and changes will also be saved."
    }
  })

  const popupCloseHandler = () => {
    setIsOpenHcpStatePopup(false);
    setCurrentActionType('')
  };

  const handleActionButtonClick = (isOpen, actionType) => {
    setIsOpenHcpStatePopup(isOpen);
    setCurrentActionType(actionType);
  }

  const CreateHcpButton = (hcpState) =>
    <Button
      variant={`${hcpState === HcpTristate[0] ? "danger" : "success"}`}
      className={`${hcpState === HcpTristate[0] ? 'secondary-button-decline-hcp' : 'primary-button-edit-country'} ${org.accountState === hcpState ? "disabled-div" : ""
        }`}
      onClick={() => handleActionButtonClick(!isOpenHcpStatePopup,
        hcpState === HcpTristate[0] ? ActionType.REJECT : ActionType.ACCEPT)}
      disabled={org.accountState === hcpState}
    >
      {hcpState === HcpTristate[0] ? "Reject" : "Accept"}
    </Button>

  const CreateDeleteOrgButton = () =>
    <Button
      variant={"danger"}
      className='secondary-button-decline-hcp'
      onClick={() => handleActionButtonClick(!isOpenHcpStatePopup, ActionType.DELETE)}
      disabled={org.accountState !== HcpTristate[0]} >
      Delete
    </Button>


  const acceptButton = CreateHcpButton(HcpTristate[2]);
  const rejectButton = CreateHcpButton(HcpTristate[0]);
  const deleteButton = CreateDeleteOrgButton()
  const correlationContextEditState = CorrelationContext["ManageHcp_EditHcpState"] + SupportId;
  const isPageDisabled = isSupportUser()
  const getInitialValuesOfFormikForm = () => {
    const activeHABrands = []
    org?.haBrands && org.haBrands.forEach(x => {
      activeHABrands.push({ label: x.brandName, value: x.haBrandId })
    })
    return { ...org, activeHABrands }
  };

  const getPopupPayload = () => {
    switch (currentActionType) {
      case ActionType.ACCEPT: return acceptPopup;
      case ActionType.REJECT: return rejectPopup;
      case ActionType.DELETE: return deletePopup;
      default: return () => null;
    }
  }

  return (
    <>

      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className={`container-fluid ${isPageDisabled ? 'disabled-div' : ''}`}>
          <div className="row m-5">
            <Formik
              onSubmit={async (values, actions) => onSubmit(values, actions, true)}
              initialValues={getInitialValuesOfFormikForm()}
              validationSchema={() => editHCPValidationSchema()}
            >
              {({ values, setFieldError, setFieldTouched, setFieldValue }) => {

                const onAccept = async () => {
                  setIsBusy(true);
                  setFieldTouched("email", true);
                  const status = await onSubmit(values, { setFieldError }, false);
                  if (status) {
                    setIsLoading(true);
                    await editHcpState(parseInt(org.organizationId), "ACTIVE", correlationContextEditState);
                    navigate(-1)
                  }
                };

                const onDecline = async () => {
                  setIsBusy(true);
                  setFieldTouched("email", true);
                  const status = await onSubmit(values, { setFieldError }, false);
                  if (status) {
                    setIsLoading(true);
                    await editHcpState(
                      parseInt(org.organizationId),
                      "REGISTRATION_REJECTED",
                      correlationContextEditState
                    );
                    navigate(-1)
                  }
                };

                const onDelete = async (e) => {
                  e.preventDefault()
                  setIsLoading(true)

                  const response = await deleteOrganization(parseInt(org.organizationId), correlationContextEditState)
                  navigate(`/organization/${org.organizationId}/result`, { state: response })
                }

                const getOkCallBack = (e) => {
                  switch (currentActionType) {
                    case ActionType.ACCEPT: return onAccept();
                    case ActionType.REJECT: return onDecline();
                    case ActionType.DELETE: return onDelete(e);
                    default: return () => { };
                  }
                }

                const onActiveSetsChange = (name, value) => {
                  setFieldValue(name, value)
                }

                return (
                  <Form className="container-fluid">
                    <div>
                      <div>
                        <div>
                          <div className="row">
                            <h2
                              className="col-md-4 offset-md-1"
                              data-qa="title"
                            >
                              {org && org.name && org.name.toUpperCase()}
                            </h2>
                            {org && (
                              <h2 className="col-md-4 offset-md-1">
                                <span
                                  className={
                                    "label-outline  " +
                                    labelStateStyle[org.accountState]
                                  }
                                >
                                  {HcpStatus[org.accountState]}
                                </span>
                              </h2>
                            )}
                          </div>
                          <br />
                          <div className="row">
                            <h4 className="col-md-4 offset-md-1">
                              Organization Details
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="organizationId"
                                label="Organization ID"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"OrganizationId"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="email"
                                label="Email"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Email"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="countryCode"
                                component={SelectField}
                                label={"Country"}
                                labelClassName="required"
                                options={Object.entries(
                                  countryListWithNames
                                ).map((x) => ({
                                  value: x[0],
                                  label: x[1],
                                }))}
                                onSelect={onChange}
                                disabled={true}
                              />

                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="street"
                                label="Street"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Street"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="zipCode"
                                label="Zip"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Zip"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="city"
                                label="City"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"City"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="phone"
                                label="Phone"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Phone"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="brandName"
                                label="Brand Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"BrandName"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <h4 className="col-md-4 offset-md-1">
                              Cluster device brand management
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="activeHABrands"
                                component={MultiSelectField}
                                label={"Active brands"}
                                options={allHABrands?.map((x) => ({
                                  label: x.brandName,
                                  value: x.haBrandId,
                                }))}
                                onChange={onActiveSetsChange}
                                value={values.activeHABrands}
                                disabled={!isAdminUser()}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <h4 className="col-md-4 offset-md-1">
                              Admin Account
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="admin.firstName"
                                label="First Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"First Name"}
                              />
                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="admin.lastName"
                                label="Last Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Last Name"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="admin.email"
                                label="Email"
                                labelClassName="required"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Email"}
                              />
                            </div>
                            <div className="col-md-4 offset-md-1 form-group">
                              <Field
                                name="admin.phone"
                                label="Phone"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"Phone"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2 offset-md-6 form-group enabled-div">
                              <button
                                className="btn btn-secondary btn-block"
                                type="button"
                                onClick={onCancel}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-md-2 form-group">
                              <Button
                                className="primary-button-edit-country"
                                variant="success"
                                type="submit"
                              >
                                Save
                                {isBusy && (
                                  <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                )}
                              </Button>
                            </div>
                          </div>

                          <div className="row">
                            <h4 className="col-md-4 offset-md-1">
                              Actions
                            </h4>
                          </div>
                          <div className="row">
                            <div className="offset-1" />
                            {isAdminUser() &&
                              <div className="col-1 form-group">
                                {deleteButton}
                              </div>}
                            <div className="col-1 form-group">
                              {rejectButton}
                            </div>
                            <div className="col-1 form-group">
                              {acceptButton}
                            </div>
                          </div>
                          <ConfirmationPopup
                            openByClickOn={acceptButton}
                            onOk={(e) => getOkCallBack(e)}
                            onClose={() => popupCloseHandler()}
                            popup={getPopupPayload()}
                            isOpen={isOpenHcpStatePopup}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuthorization(
  withRouter(EditHCP),
  PageAuthorizations.EditHCP.authorizedRoles
);
