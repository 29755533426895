import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import {
  importTranslationsForFaqService,
  importTranslationsForTcbe,
  importTranslationsForDailySatisfactions,
  importTranslationsForTelecareLessons,
  importAllTranslations,
} from "../../../services/ImportTranslations";
import Loading from "../../loading";
import { SuccessPopup } from "../../../popups/SuccessPopup";

export const ImportTranslations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState("faq");
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [apiResponse, setApiResponse] = useState(false);
  const [setStatusCode] = useState(200);

  const importFaq = async () => {
    setIsLoading(true);
    let response = await importTranslationsForFaqService();
    processApiResult(response);
    setIsLoading(false);
    setTogglePopUp(true);
  };

  const importTcbe = async () => {
    setIsLoading(true);
    let response = await importTranslationsForTcbe();
    processApiResult(response);
    setIsLoading(false);
    setTogglePopUp(true);
  };


  const importDailySatisfactions = async () => {
    setIsLoading(true);
    let response = await importTranslationsForDailySatisfactions();
    processApiResult(response);
    setIsLoading(false);
    setTogglePopUp(true);
  };

  const importTelecareLessons = async () => {
    setIsLoading(true);
    let response = await importTranslationsForTelecareLessons();
    processApiResult(response);
    setIsLoading(false);
    setTogglePopUp(true);
  };

  const importAll = async () => {
    setIsLoading(true);
    let response = await importAllTranslations();
    processApiResult(response);
    setIsLoading(false);
    setTogglePopUp(true);
  };

  const importSelected = async () => {
    if (selected === "faq") {
      await importFaq();
    } else if (selected === "telecareBackend") {
      await importTcbe();
    } else if (selected === "dailySatisfactions") {
      await importDailySatisfactions();
    } else if (selected === "telecareLessons") {
      await importTelecareLessons();
    } else if (selected === "importAll") {
      await importAll();
    }
  };

  const processApiResult = response => {
    if (response.statusCode === 200) {
      setApiResponse(response.data);
    }
    else {
      setStatusCode(response.statusCode);
    }
  }
  const handleInputChange = (event) => {
    const target = event.target;
    setSelected(target.value);
  };
  const onClick = () => {
    setTogglePopUp(false);
  };
  let loadingIndicator = isLoading ? (
    <Loading isLoading={isLoading} maxTimeout={isLoading ? true : false} />
  ) : (
    <div />
  );

  return (
    <div className="container">
      {loadingIndicator}
      {togglePopUp ? (
        <SuccessPopup
          title={apiResponse ? "Phrase translations" : "Problem at server"}
          message={
            apiResponse ? "Successfully imported" : "Something went wrong"
          }
          shouldHide={onClick}
        />
      ) : (
        <Col md={8} style={{ padding: 0 }}>
          <Card>
            <Card.Header>Import phrase translations</Card.Header>
            <Card.Body>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "faq"}
                    value={"faq"}
                    onChange={handleInputChange}
                    type="radio"
                  />
                </Col>
                <Col sm={6}>Frequently Asked Questions (FAQ )</Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "telecareBackend"}
                    value={"telecareBackend"}
                    onChange={handleInputChange}
                    type="radio"
                  />
                </Col>
                <Col sm={6}>Telecare Backend</Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "dailySatisfactions"}
                    value={"dailySatisfactions"}
                    onChange={handleInputChange}
                    type="radio"
                  />
                </Col>
                <Col sm={6}>Daily Satisfactions</Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "telecareLessons"}
                    value={"telecareLessons"}
                    onChange={handleInputChange}
                    type="radio"
                  />
                </Col>
                <Col sm={6}>Telecare Lessons</Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "importAll"}
                    value={"importAll"}
                    onChange={handleInputChange}
                    type="radio"
                  />
                </Col>
                <Col sm={6}>Import all</Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <input
                    name="selected"
                    checked={selected === "telecarePortal"}
                    value={"telecarePortal"}
                    type="radio"
                    disabled
                  />
                </Col>
                <Col sm={6}>Telecare Portal (Auto import with next release)</Col>
              </Row>
              <Row>
                <Col sm={9}></Col>
                <Col sm={2}>
                  <Button onClick={importSelected}>Import</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default withAuthorization(
  ImportTranslations,
  PageAuthorizations.ImportTranslations.authorizedRoles
);
