import { adalApiFetch } from "../auth/adalConfig";
import queryString from 'query-string'
import { getOptionsWithCorrelationContext } from '../helper/constants';

export const getHCP = async (queryStringParams) => {
  return fetchData('/api/accounts?' + queryString.stringify(queryStringParams))
}

export const getHCPById = async (id, correlationContext = "") => {
  let options = {
    method: "get",
    headers: { 'Content-Type': 'application/ json' }
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, `/api/accounts/${id}`, options)
    .then(async response => {
      if (response.status === 200) {
        let json = await response.json()
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch(err => {
      console.log(err)
    })
}

export const editHcp = async (id, hcpDetails, correlationContext = "") => {
  let options = {
    method: "put",
    headers: { 'Content-Type': 'application/ json' },
    body: JSON.stringify(hcpDetails)
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, `/api/accounts/${id}`, options)
    .then(response => {
      if (response.status >= 200 && response.status <= 300) {
        return { statusCode: response.status }
      }
      return response.json();
    })
    .catch(err => {
      console.log(err)
    })
}

export const editHcpState = async (id, accountState, correlationContext = "") => {
  let options = {
    method: "put",
    headers: { 'Content-Type': 'application/ json' },
    body: JSON.stringify({ state: accountState })
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, `/api/accounts/${id}/state`, options)
    .then(response => {
      if (response.status >= 200 && response.status <= 300) {
        return { statusCode: response.status }
      }
      return response.json();
    })
    .catch(err => {
      console.log(err)
    })
}

export const getPracticesByOrganizationId = async (organizationId) => {
  return fetchData(`/api/accounts/practices/${organizationId}`)
}

const fetchData = (url, correlationContext = "") => {
  let options = {
    method: "get",
    headers: { 'Content-Type': 'application/ json' }
  };

  options = getOptionsWithCorrelationContext(options, correlationContext);

  return adalApiFetch(fetch, url, options)
    .then(async response => {
      if (response.status === 200) {
        let json = await response.json()
        return { data: json, statusCode: 200 }
      }
      return { statusCode: response.status }
    })
    .catch(err => {
      console.log(err)
    })

}

export const deleteOrganization = async (id, correlationContext = "") => {
  let options = {
    method: "delete",
    headers: { 'Content-Type': 'application/ json' }
  }

  options = getOptionsWithCorrelationContext(options, correlationContext)

  return adalApiFetch(fetch, `/api/accounts/rejected/${id}`, options)
    .then(async response => {
      let json = await response.json()
      return { data: json }
    })
    .catch(err => {
      console.log(err)
    })
}