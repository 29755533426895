import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	> div {
		min-width: 160px;
	}
`
export const SearchWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`
export const ActionIcon = styled.div`
  ::before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
    font-size: 24px;
    position: absolute;
    width: 35px;
    right: 20px;
	cursor: pointer;
    content: "${(props) => (props.isActive ? '\f00d' : '\f002')}";
  }`