import React from "react";
import { Field } from "formik";
import TextField from "./TextField";

export const TextFieldArray = (data) => {
  const { push, remove, label, name, isReadOnly, form, className, placeholder, maxSize } = data;
  const fieldArray = form.values[name];
  return (
    <div>
      {fieldArray.map((fieldArrayName, fieldArrayIndex) => {
        return (
          <React.Fragment key={fieldArrayIndex}>
            <div>
                <Field
                  disabled={isReadOnly}
                  name={`${name}.${fieldArrayIndex}.name`}
                  component={TextField}
                  placeholder={placeholder}
                  className={`${className}`}
                  label={label}
                  index={fieldArrayIndex}
                  push={push}
                  remove={remove}
                  isReadOnly={isReadOnly}
                  length={fieldArray.length}
                  maxSize={maxSize}
                />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TextFieldArray;
