import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "./bootstrap-custom.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "@formatjs/intl-pluralrules/polyfill";
import en from "@formatjs/intl-pluralrules/locale-data/en";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./components/common/customYupValidations";
import { IntlProvider } from "react-intl";

const rootElement = document.getElementById("root");
// load the default language
new IntlProvider(en);
ReactDOM.render(
  <IntlProvider locale="en" defaultLocale="en">
    <Router>
      <App />
    </Router>
  </IntlProvider>,
  rootElement
);

registerServiceWorker();
