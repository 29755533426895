import React from "react";
import { Link } from "react-router-dom";
import { PageAuthorizations } from "../../auth/pageAuthorization";
import * as AdalFacade from "../../auth/adalFacade";

const Navigation = ({ activeLink }) => {
  const menusUserIsAuthorized = () => {
    let UserHasRole = (r) => AdalFacade.getUserRoles().includes(r);
    let UserAuthorizedToSeePage = (p) => p.authorizedRoles.some(UserHasRole);

    const pageList = PageAuthorizations.asList()
      .filter(UserAuthorizedToSeePage)
      .map((p) => p.page)
      .filter((p) => p.isNavigable);
    const navigationClass = pageList.length < 3 ? "col-md-3" : "col-md";
    return pageList.map((p, index) => (
      <div key={`menu-${index}`} className={navigationClass}>
        <Link
          to={p.route}
          className={`patient-navigation__menu-link  ${activeLink.includes(p.route) ? "active" : ""
            }`}
        >
          {p.name}
        </Link>
      </div>
    ));
  };

  return (
    <div className="patient-navigation container-fluid" fixed="top">
      <div className="row">
        <div className="col-md-12">
          <ul
            data-qa="patient-navigation_links"
            className="row patient-navigation__menu pl-4"
          >
            {menusUserIsAuthorized()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
