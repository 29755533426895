export const Roles = {
  RegularUser: "RegularUser",
  Ambassador: "AmbassadorUser",
  Admin: "Admin",
  SupportUser: "Support",
};

class Page {
  constructor(name, route, icon, isNavigable) {
    this.name = name;
    this.route = route;
    this.icon = icon;
    this.isNavigable = isNavigable;
  }
}

class PageAuthorization {
  constructor(page, authorizedRoles) {
    this.page = page;
    this.authorizedRoles = authorizedRoles;
  }
}

export const Pages = {
  Home: new Page("Home", "/", "home", true),
  SearchTelecareAccount: new Page(
    "Search Telecare Account",
    "/reset-password",
    "education",
    true
  ),
  CountryManagement: new Page(
    "Country Management",
    "/country",
    "country",
    true
  ),
  EditCountry: new Page(
    "Edit Country",
    "/country/{countrycode}",
    "country",
    false
  ),
  CreateCountry: new Page("Edit Country", "/country/add", "country", false),
  AccountManagement: new Page(
    "User Management",
    "/accounts",
    "accounts",
    true
  ),
  EditAccount: new Page("Edit Account", "/accounts/{id}", "accounts", false),
  CreateAccount: new Page("Create Account", "/accounts/add", "accounts", false),
  OrgManagement: new Page("Org Management", "/organization", "organization", true),
  EditOrganization: new Page("Edit Organization", "/organization/{id}", "organization", false),
  DeleteOrganizationResult: new Page("Delete Organization Result", "/organization/{id}/result", "organization", false),
  ImportTranslations: new Page(
    "Import Translations",
    "/translations",
    "translations",
    true
  ),
  MergeOrganizations: new Page(
    "Merge Organizations",
    "/mergeOrganizations",
    "mergeOrganizations",
    true
  ),
  MergeOrganizationsResult: new Page(
    "Merge Organizations Result",
    "/mergeOrganizations/result",
    "mergeOrganizations",
    false
  ),
  WhatsNew: new Page("Release notes", "/whatsnew", "whatsNew", false),
};

export const PageAuthorizations = {
  SearchTelecareAccount: new PageAuthorization(Pages.SearchTelecareAccount, [
    Roles.SupportUser,
    Roles.Admin,
    Roles.Ambassador,
  ]),
  CountryManagement: new PageAuthorization(Pages.CountryManagement, [
    Roles.Admin,
  ]),
  EditCountry: new PageAuthorization(Pages.EditCountry, [Roles.Admin]),
  CreateCountry: new PageAuthorization(Pages.CreateCountry, [Roles.Admin]),
  AccountManagement: new PageAuthorization(Pages.AccountManagement, [
    Roles.Admin,
  ]),
  EditAccount: new PageAuthorization(Pages.EditAccount, [Roles.Admin]),
  CreateAccount: new PageAuthorization(Pages.CreateAccount, [Roles.Admin]),
  HCPManagement: new PageAuthorization(Pages.OrgManagement, [
    Roles.Ambassador,
    Roles.Admin,
    Roles.SupportUser,
  ]),
  EditHCP: new PageAuthorization(Pages.EditOrganization, [
    Roles.Ambassador,
    Roles.Admin,
    Roles.SupportUser,
  ]),
  DeleteOrganizationResult: new PageAuthorization(Pages.DeleteOrganizationResult, [Roles.Admin]),
  ImportTranslations: new PageAuthorization(Pages.ImportTranslations, [
    Roles.Admin,
  ]),
  MergeOrganizations: new PageAuthorization(Pages.MergeOrganizations, [
    Roles.Admin,
  ]),
  MergeOrganizationsResult: new PageAuthorization(
    Pages.MergeOrganizationsResult,
    [Roles.Admin]
  ),
  WhatsNew: new PageAuthorization(Pages.WhatsNew, [
    Roles.Ambassador,
    Roles.Admin,
    Roles.SupportUser,
  ]),
  asList: () => {
    return Object.values(PageAuthorizations).filter(
      (m) => typeof m !== "function"
    );
  },
};
