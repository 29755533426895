import styled from 'styled-components'

export const Input = styled.input`
	width: ${(props) => (props.width ? props.width : '100%': string)};
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	color: #454545;
	border: 1px solid ${(props) => (props.isValid ? '#9d9c9c' : '#d60925')};
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s,
		-webkit-box-shadow ease-in-out .15s;
`

export const InputUrl = styled.input`
	border: 1px solid;
	font-size: 1.2em;
	line-height: 1.5em;
	padding: 10px;
	height: 50px;
	width: 500px;
`

export const StyledInput = styled.input`
	border-radius: 20px;
	width: 300px;
	height: 36px;
	padding: 5px 10px;
	border: 1px solid #9d9c9c;
	outline: none;
	@media (min-width: 1200px) {
		width: 400px;
	}
	@media (max-width: 576px) {
		width: 100%;
	}
	&:focus {
		width: 100%;
		transition: width 0.5s ease-in-out;
	}
`
