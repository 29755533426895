import * as AdalFacade from "../../auth/adalFacade";

export const isSupportUser = () =>{
  let userRoles = AdalFacade.getUserRoles()
  if(userRoles.length === 1 && userRoles.includes('Support'))
  {
    return true
  }
  return false
}

export const isAdminUser = () =>{
  let userRoles = AdalFacade.getUserRoles()
  if(userRoles.length === 1 && userRoles.includes('Admin'))
  {
    return true
  }
  return false
}