export const P_SUCCESS = 'P_SUCCESS'
export const SHOW_POPUP = 'SHOW_POPUP'
export const HIDE_POPUP = 'HIDE_POPUP'
export const P_DEFAULT_POPUP = 'P_DEFAULT_POPUP'
export const DELETE_POPUP = 'DELETE_POPUP'
export const ERROR_POPUP = 'ERROR_POPUP'

export const CONFIRMATION_POPUP = 'CONFIRMATION_POPUP'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'

export const getOverlayUpdateData = (type, action) => {
  const updateState = {
    [SHOW_POPUP]: () => ({
      type: action.type,
      payload: action.payload
    }),
    [HIDE_POPUP]: () => ({
      popup: null,
      isOpen: false
    }),
    [SHOW_MODAL]: () => ({
      modal: true
    }),
    [HIDE_MODAL]: () => ({
      modal: false,
      isOpen: false
    }),
    [OPEN_MODAL]: () => ({
      isOpen: true
    })
  }[type]
  return updateState(action)
}
