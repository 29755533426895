import { runWithAdal } from 'react-adal';
import { authContext } from './auth/adalConfig';
import { registerFetchIntercept } from "./helper/fetchInterceptor";

const DO_NOT_LOGIN = false;
(async function () {
  registerFetchIntercept();
  const authContextData = await authContext()
  runWithAdal(authContextData, () => {
    // eslint-disable-next-line
    window.authContext = authContextData;
    require('./indexApp.js');

  }, DO_NOT_LOGIN);
})();