import React from "react";
import { ErrorMessage } from "./ErrorMessage";
import { getIn } from "formik";

const TextField = ({
  field,
  className,
  label,
  labelClassName,
  form:{ touched, errors },
  index,
  push,
  remove,
  isReadOnly,
  length,
  maxSize,
  ...props
}) => {
  return (
    <div
      className={`form-group ${
        getIn(errors, field.name) && getIn(touched, field.name)
          ? "has-error"
          : ""
      }`}
    >
      {label && index === 0 && (
        <label
          htmlFor={field.name}
          className={`control-label ${labelClassName || ""}`}
        >
          {label}
        </label>
      )}
      
      <div className="row reset-margin reset-flex">
      <div className={`reset-padding textbox-flex`}>
        <input
          {...field}
          {...props}
          className={`form-control ${className || ""} ${index > 0 || length === 1 ? 'text-field-border ' : ''} ${getIn(errors, field.name) && getIn(touched, field.name) ? "has-error" : ""}`}
          autoComplete={props.autoComplete}
        />
      </div>
    <div className="align-items-end reset-padding">
    {index > 0 ? (
        <div className={`trash reset-height ${index < length - 1 ? 'trash-border' : ''}`}>
          <i
            className={`fa fa-trash`}
            disabled={isReadOnly}
            onClick={() => remove(index)}
          ></i>
        </div>
      ) : (
        <></>
      )}
      {index === length - 1 ? (
        <div className={`add reset-height`}>
          <i
            className={`fa ${isReadOnly || index >= maxSize - 1 ? 'fa-ban not-active': 'fa-plus'}`}
            onClick={() => push({name:''})}
            data-qa="add-secondary-field"
            type="button"
          ></i>
        </div>
      ) : (
        <></>
      )}
    </div>
    </div>
  
      <ErrorMessage
        name={field.name}
      />
    </div>
  );
};

export default TextField;
