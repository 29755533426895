import React, { Component } from "react"
import { Link } from "react-router-dom"
import { withReleaseNotes } from '../components/higher-order-components/ReleaseNotesHOC'
import { ReleaseNotesContent } from '../components/ReleaseNotesContent'

const limit = 5
export class ReleaseNotes extends Component {
  displayName = ReleaseNotes.name

  render() {
    const { releaseNotes } = this.props
    return (
      <div className="m-5 col-5">
        <ReleaseNotesContent releaseNotes={releaseNotes} />
        <div className="mt-1">
          <Link to={"/whatsnew"} target="_blank">Read more</Link>
        </div>
      </div>
    )
  }
}

export default withReleaseNotes(ReleaseNotes, limit)
