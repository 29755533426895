import { adalApiFetch } from "../auth/adalConfig"
import { getOptionsWithCorrelationContext } from '../helper/constants';

export default class ManageAccounts {


  static fetchAccountsByUsername(username, correlationContext = "") {
    let options = {
      method: "get",
      headers: {
        "Content-Type": "application/ json",
      },
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch, '/api/Users/ByUsername/' + username, options);
  }

  static requestPasswordResetByUsername(selectedId, correlationContext = "") {
    let options = {
      method: "post",
      body: JSON.stringify({ SecurityUserId: selectedId }),
      headers: {
        "Content-Type": "application/ json",
      },
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch,
      '/api/Users/RequestPasswordReset', options);
  }

  static isUserPresentForTelecareFeature(username, correlationContext = "") {
    let options = {
      method: "get",
      headers: {
        "Content-Type": "application/ json",
      },
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);
    return adalApiFetch(fetch, `/api/Users/telecare-3.0/${username}`, options);
  }

  static async fetchAccountDetails(username) {
    let promises = await Promise.all([
      this.fetchAccountsByUsername(username),
      this.isUserPresentForTelecareFeature(username),
    ]);

    let accountDetails;
    if (promises[0].status === 404) {
      accountDetails = { status: promises[0].status };
    } else {
      accountDetails = await promises[0].json();
    }
    let isUserPresentForTelecareFeature = await promises[1].json();

    return Object.assign(
      {},
      { ...accountDetails },
      { isUserPresentForTelecareFeature: isUserPresentForTelecareFeature }
    );
  }

  static updateTelecareFeature(userName, isEnabled, correlationContext = "") {
    let options = {
      method: "put",
      body: JSON.stringify({ email: userName, isEnabled }),
      headers: {
        "Content-Type": "application/ json",
      },
    };

    options = getOptionsWithCorrelationContext(options, correlationContext);

    return adalApiFetch(fetch,
      '/api/Users/telecare-3.0', options);
  }

}