import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { orderBy } from 'lodash'

import { getAccountManagers } from "../../../services/ManageAccountManagers";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import Loading from "../../loading";
import AccountTable from "./accountTable"
import ErrorResponse from "../../common/errorResponse"
import ErrorPopup from "../../../popups/ErrorPopup";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'
import {
  SHOW_POPUP,
  ERROR_POPUP,
  getOverlayUpdateData,
} from "../../../popups/OverlayUpdateData"

let SupportId = getSupportId()

export const AccountManagement = (props) => {
  const [accountManagers, setAccountManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(200)
  const [sort, setSort] = useState('countryCode asc')
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false)
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("")

  useEffect(() => {
    async function fetchAccountManagers() {
      setIsLoading(true)
      const correlationContext = CorrelationContext["ManageUsers_GetAccountManager"] + SupportId;
      const accountManagersData = await getAccountManagers(correlationContext);
      processApiResult(accountManagersData)
      setIsLoading(false)
    }
    fetchAccountManagers();
  }, []);

  const processApiResult = accountManagersData => {
    if (accountManagersData.statusCode === 200) {
      setAccountManagers(accountManagersData.data)
    }
    else {
      setStatusCode(accountManagersData.statusCode)

    }
  }
  const errorPopup = getOverlayUpdateData(SHOW_POPUP, {
    type: ERROR_POPUP,
    payload: {
      title: "Error Occured",
      message: deleteErrorMessage,
    },
  });

  const handleDeleteError = (errorMessage) => {
    setDeleteErrorMessage(errorMessage);
    setIsOpenErrorPopup(true);
  }

  const performSorting = (sortField, order) => {
    const sortedData = orderBy(accountManagers, [sortField], [order])
    setAccountManagers(sortedData)
  }

  const setSorting = sortField => {
    const prevOrder = sort.split(',')[1] || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'
    const newSort = [sortField, order].join(',')
    setSort(newSort)
    performSorting(sortField, order)
  }


  if (statusCode !== 200)
    return (<ErrorResponse statusCode={statusCode} />)
  return (
    <div className="container-fluid row m-5">
      <div className="col-md-4 pl-0"><h2><b>Users</b></h2></div>
      <div className="col-md-2 offset-md-6">
        <Button
          className="primary-button-edit-country"
          variant="success"
          href="/accounts/add"
        >
          Create User
        </Button>
      </div>
      {accountManagers && accountManagers.length === 0 ?
        <Loading isLoading={isLoading} />
        : <AccountTable accountManagers={accountManagers} sort={sort} setSorting={setSorting} {...props} onError={handleDeleteError} />}


      <ErrorPopup
        popup={errorPopup}
        isOpen={isOpenErrorPopup}
        onConfirm={() => setIsOpenErrorPopup(false)}
        onClose={() => setIsOpenErrorPopup(false)}
      />
    </div>
  );
};

export default withAuthorization(
  AccountManagement,
  PageAuthorizations.AccountManagement.authorizedRoles
);
