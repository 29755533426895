import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
import { authContext } from '../../src/auth/adalConfig'

const SERVICE_PORTAL_VERSION = process.env.REACT_APP_SERVICE_PORTAL_VERSION || "";
const TFS_BUILDNUMBER = process.env.REACT_APP_TFS_BUILDNUMBER
export class NavMenu extends Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        variant="dark"
        fixed="top"
        expand="sm"
        className="navigation-bar"
      >
        <Navbar.Brand className="ml-5 mr-auto">
          <Link to={"/"}>
            <b>
              <h2>Service Portal</h2>
            </b>
            <h6 className="version-cursor">
              v{SERVICE_PORTAL_VERSION + (TFS_BUILDNUMBER === "" ? "" : `.${TFS_BUILDNUMBER}`)}
            </h6>
          </Link>
        </Navbar.Brand>
        <Button
          className="mr-4"
          variant="danger"
          onClick={async function () {
            const authContextData = await authContext();
            authContextData.logOut();
          }}
        >
          <b>Logout</b>
        </Button>
      </Navbar>
    );
  }
}
