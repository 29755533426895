import React from "react";

export const TriStateButton = ({ state, id, triStates, tooltips, onHcpStateChange, disabled }) => {
  const onStateClick = (event) => {
    onHcpStateChange(id, event.target.getAttribute("state"));
  };
  return (
    <div className={`switch-toggle switch-candy switch-ios ${disabled ? 'disabled-div' : ''}`}>
      <input
        id={id + "r"}
        name={id}
        type="radio"
        checked={state === triStates[0]}
        state={triStates[0]}
        onChange={onStateClick}
        disabled={state === triStates[0]}
      />
      <label htmlFor={id + "r"}>
        <i
          className={`fa fa-close reject ${
            state === triStates[0] ? "" : "hand-cursor"
          }`}
          aria-hidden="true"
          title={
            state === triStates[0]
              ? tooltips[1]
              : tooltips[0]
          }
        ></i>
      </label>

      <input
        id={id + "p"}
        name={id}
        type="radio"
        checked={state === triStates[1]}
        disabled
        readOnly
      />
      <label htmlFor={id + "p"} disabled>
        <i className="fa fa-clock-o pending" aria-hidden="true" title={tooltips[2]}></i>
      </label>

      <input
        id={id + "a"}
        name={id}
        type="radio"
        checked={state === triStates[2]}
        state={triStates[2]}
        onChange={onStateClick}
        disabled={state === triStates[2]}
      />
      <label htmlFor={id + "a"}>
        <i
          className={`fa fa-check accept ${state === triStates[2] ? "" : "hand-cursor"}`}
          aria-hidden="true"
          title={state === triStates[2] ? tooltips[4] : tooltips[3]}
        ></i>
      </label>
      <a></a>
    </div>
  );
};
