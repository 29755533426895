import React, { useState } from 'react'
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'
import { deleteCountryCallingCode } from "../../../services/ManageCountryCallingCode";
import {
  DELETE_POPUP,
  SHOW_POPUP,
  getOverlayUpdateData,
} from "../../../popups/OverlayUpdateData";
import ConfirmDeletionPopup from "../../../popups/confirmDeletionPopup"
import moment from "moment"

let SupportId = getSupportId()
const DefaultCountryCodeConst = 'Default'

const CountryCodeTable = ({ editedRow, countryCodes = [], setcountrycodes, setIsLoading, fetchCountryCallingCode }) => {

  const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false)
  const [countryCallingCodeForDelete, setCountryCallingCodeForDelete] = useState("");

  const confirmDeletePopupData = getOverlayUpdateData(SHOW_POPUP, {
    type: DELETE_POPUP,
    payload: {
      title: "Delete country calling config",
      message: "Are you sure you want to delete this configuration?",
    },
  })

  const handleEditConfig = (country) => {
    editedRow(country)
  }

  const hadleDeletePopup = (countryCallingCode) => {
    setCountryCallingCodeForDelete(countryCallingCode);
    setIsDeletionPopupOpen(!isDeletionPopupOpen);

  }

  const handleDeleteConfig = async (countryCallingCode) => {
    setIsDeletionPopupOpen(false)
    setIsLoading(true)
    const correlationContext = CorrelationContext["ManageUsers_DeleteAccountManager"] + SupportId;

    deleteCountryCallingCode(countryCallingCode, correlationContext)
      .then(res => {
        fetchCountryCallingCode();
      })
      .finally(() => { setIsLoading(false) })
  }

  return (
    <>
      <table className="table table-striped mt-5">
        <thead>
          <tr>
            <th className="col-md-2" name="countryCode">
              Country Prefix
            </th>
            <th className="col-md-2" name="countryName">
              Country Name
            </th>
            <th className="col-md-2" name="senderNumber">
              Sender ID
            </th>
            <th className="col-md-1" name="enableSenderNumberConfig">
              Enabled <i className="fa fa-info-circle" title='Enable Sender Number' aria-hidden="true"></i>
            </th>
            <th className="col-md-1" name="blockSendingNotifications">
              Blocked <i className="fa fa-info-circle" title='Block notification for this calling code' aria-hidden="true"></i>
            </th>
            <th className="col-md-2" name="description">
              Description
            </th>
            <th className="col-md-2" name="lastModified">
              Last Modified
            </th>
            <th className="col-md-1" name="action">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {countryCodes?.map((country) =>
          (
            <tr key={country.countryCallingCode}>
              <td>
                {country.countryCallingCode}
              </td>
              <td>
                {country.countryName}
              </td>
              <td>
                {country.senderNumber}
              </td>
              <td>
                {renderConfigValue(country.isSenderConfigEnabled)}
              </td>
              <td>
                {renderConfigValue(country.isCallingCodeBlocked)}
              </td>
              <td >
                <span className='country-description-truncate' data-text={country.description}>{country.description}</span>
              </td>
              <td>
                {moment(country.lastModified).format('YYYY-MM-DD')}
              </td>
              <td>
                <i className="fa fa-edit fa-fw edit-delete-button" onClick={() => handleEditConfig(country)} />
                {country.countryCallingCode !== DefaultCountryCodeConst &&
                  <i className="fa fa-trash fa-fw icon-link edit-delete-button"
                    onClick={() => hadleDeletePopup(country.countryCallingCode)} />}
              </td>
            </tr>
          )
          )}
        </tbody>
      </table>
      <ConfirmDeletionPopup
        onDelete={() => handleDeleteConfig(countryCallingCodeForDelete)}
        onClose={() => setIsDeletionPopupOpen(false)}
        popup={confirmDeletePopupData}
        isOpen={isDeletionPopupOpen}
      />
    </>
  )
}

const optionYes = (
  <span>
    <i className="fa fa-check fa-md mr-1" title='yes' />
  </span>
)

const optionNo = (
  <span>
    <i className="fa fa-times fa-md mr-1" title='No' />
  </span>
)

function renderConfigValue(isChecked) {
  return isChecked ? optionYes : optionNo;
}

export default CountryCodeTable
