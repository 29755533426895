import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

class AccessibleAccordion extends React.Component {
  render() {
    const { header, content, uuid, preExpandedUuid=[]} = this.props;
    return (
      <Accordion allowZeroExpanded={true} onChange={this.props?.onChange} preExpanded={[...preExpandedUuid]}>
        <AccordionItem uuid={uuid}>
          <AccordionItemHeading>
            <AccordionItemButton
              className="accordion__button org-accordion-item"
            >
              {header}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{content}</AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default AccessibleAccordion;
