import React from 'react'
import { ErrorMessage, getIn } from 'formik'
import Downshift from 'downshift';

const AutoCompleteField = ({
  field: { name: fieldName, value: fieldValue, onBlur, onChange },
  className,
  label,
  labelClassName,
  form: { touched, errors },
  userlist,
  onInputValueChange,
  onItemSelected,
  ...props
}) => {
  const inputFIeldProps = {
    name: fieldName,
    value: fieldValue,
    ...props,
    className: `form-control ${className || ""} ${
      getIn(errors, fieldName) && getIn(touched, fieldName) ? "has-error" : ""
      } `,
    onBlur,
    onChange
  };  

  return (

    <Downshift
      onChange={onItemSelected}
      onInputValueChange={onInputValueChange}
      itemToString={item => (item ? item.mail : "")}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex
      }) => (
          <div
            className={`form-group ${
              getIn(errors, fieldName) && getIn(touched, fieldName)
                ? "has-error"
                : ""
              }`}
          >
            {label && (
              <label
                htmlFor={fieldName}
                className={`control-label ${labelClassName || ""}`}
              >
                {label}
              </label>
            )}
            <input   {...getInputProps({ ...inputFIeldProps, placeholder: 'e-mail' })} />
            {isOpen ? (
              <div className="autocomplete-items">
                {userlist
                  .filter(
                    user =>
                      !inputValue ||
                      user.userPrincipalName.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        {...getItemProps({ item, index })}
                        key={index}
                        style={{
                          color: '#212529',
                          backgroundColor:
                            highlightedIndex === index ? "#f2f2f2" : "white",
                          fontWeight:
                            selectedItem && selectedItem.userPrincipalName === item.userPrincipalName || fieldValue === item.userPrincipalName
                              ? "bold"
                              : "normal"
                        }}
                      >
                        {item.userPrincipalName}
                      </div>
                    );
                  })}
              </div>
            ) : null}
            <ErrorMessage
              name={fieldName}
              render={msg => (
                <div className="help-block" data-qa="input-error">
                  {msg}
                </div>
              )}
            />
          </div>
        )}
    </Downshift>
  )

}

export default AutoCompleteField
