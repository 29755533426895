import React, { Component } from "react"
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReleaseNotes from './ReleaseNotes'

export default class Home extends Component {
  displayName = Home.name;

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <Jumbotron className="m-5 col-3 align-self-start">
            <h1>Service Portal</h1>
            <p>Welcome to the Sivantos service portal for telecare</p>
            <p>Please select the different services available from the menu</p>
          </Jumbotron>
          <ReleaseNotes />
        </div>
      </div>
    )
  }
}
