import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { chain } from 'lodash'
import SortableTableHeader from '../../common/sortableTableHeader'
import {
  deleteAccountManager
} from "../../../services/ManageAccountManagers";
import ConfirmDeletionPopup from "../../../popups/confirmDeletionPopup";
import {
  DELETE_POPUP,
  SHOW_POPUP,
  getOverlayUpdateData,
} from "../../../popups/OverlayUpdateData";
import { getUserUniqueName } from "../../../auth/adalFacade";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'

let SupportId = getSupportId()
const AccountTable = ({ sort, setSorting, accountManagers = [], onError, history }) => {
  const [isOpenDeletionPopup, setIsOpenDeletionPopup] = useState(false);
  const [accountIdForDelete, setAccountIdForDelete] = useState("");
  const setSort = sortField => () => {
    setSorting(sortField)
  }
  const popup = getOverlayUpdateData(SHOW_POPUP, {
    type: DELETE_POPUP,
    payload: {
      title: "Delete Account Manager",
      message: "Are you sure to delete this Account Manager ?",
    },
  })


  const popupCloseHandler = () => {
    setIsOpenDeletionPopup(false)
  }



  const removeAccountManager = async (id) => {
    const correlationContext = CorrelationContext["ManageUsers_DeleteAccountManager"] + SupportId;
    deleteAccountManager(id, correlationContext).then(res => {
      if (res && res.error_code) {
        onError(res.description);
      }
      else {
        window.location.reload()
      }
    });
  };

  const handleDeletePopup = (id) => {
    setAccountIdForDelete(id);
    setIsOpenDeletionPopup(!isOpenDeletionPopup);
  }

  return (
    <>
      <table className="table table--users mt-5">
        <thead>
          <tr>
            <SortableTableHeader
              className="col-md-2"
              name="firstName"
              onClick={setSort('firstName')}
              value={'First Name'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-2"
              name="lastName"
              onClick={setSort('lastName')}
              value={'Last Name'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-4"
              name="email"
              onClick={setSort('email')}
              value={'Email'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-2"
              name="role"
              onClick={setSort('role')}
              value={'Role'}
              sort={sort}
            />
            <SortableTableHeader
              className="col-md-4"
              name="countryCodes"
              onClick={setSort('countryCodes')}
              value={'Markets'}
              sort={sort}
            />
            <th className="col-md-2" ></th>
            <th className="col-md-2" ></th>
          </tr>
        </thead>
        <tbody>
          {accountManagers && accountManagers.map(accountManager => {
            return (
              <tr key={accountManager.id}>
                <td>
                  {accountManager.firstName}
                </td>
                <td>
                  {accountManager.lastName}
                </td>
                <td>
                  {!accountManager.email ? '-' : accountManager.email}
                </td>
                <td>
                  {accountManager.role}
                </td>
                <td>
                  {!accountManager.countries ? '-' : chain(accountManager.countries).join(',').value()}
                </td>
                <td className="hcp-pull-right">
                  {
                    <Link
                      data-qa="manage__edit-button"
                      to={{
                        pathname: `/accounts/${accountManager.id}`,
                        state: { accountManager }
                      }}
                      data-tip
                      data-for={`tooltip-edit-${accountManager.id}`}
                    >
                      <i className="fa fa-edit fa-fw edit-delete-button" />
                    </Link>
                  }
                </td>
                <td>
                  {getUserUniqueName() !== accountManager.email
                    && <i className="fa fa-trash fa-fw icon-link edit-delete-button"
                      onClick={() => handleDeletePopup(accountManager.id)} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ConfirmDeletionPopup
        onDelete={() => removeAccountManager(accountIdForDelete)}
        onClose={() => popupCloseHandler()}
        popup={popup}
        isOpen={isOpenDeletionPopup}

      />
    </>
  )
}

export default AccountTable
