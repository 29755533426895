import React from 'react'
import Select from 'react-select'
import { ErrorMessage, getIn } from 'formik'

const MultiSelectField = ({
  options = {},
  labelClassName,
  disabled,
  label,
  value,
  onChange,
  placeholder = '',
  field,
  form: { touched, errors, setFieldTouched }
}) => {
  const labelClasses = (labelClassName || '').split(' ')
  const getBackgroundColor = (isDisabled, isFocused, isSelected) => {
      if(isDisabled){
          return null;
      } else if(isSelected){
          return '#cfcfcf';
      } else if(isFocused){
          return '#e3e3e3';
      } else{
          return null;
      }
  }
  labelClasses.push('control-label')
  return (
    <div
      className={`form-group ${getIn(touched, field.name) && getIn(errors, field.name)
        ? 'has-error'
        : ''}`}
      data-testid="multi-select-field"
    >
      {label &&
        <label htmlFor={field.name} className={labelClasses.join(' ')}>
          {label}
        </label>}
      <div className="MultiSelectField">
        <Select
          isDisabled={disabled}
          closeMenuOnSelect={false}
          name={field.name}
          isMulti={true}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={val => onChange(field.name, val)}
          onBlur={() => setFieldTouched(field.name)}
          styles={{
            control: base => ({
              ...base,
              border: '1px solid #9d9c9c',
              boxShadow: 'none',
              cursor: 'pointer'
            }),
            option: (styles, { isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: getBackgroundColor(isDisabled, isFocused, isSelected),
                color: isDisabled ? '#ccc' : '#3a3a3a',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                ':active': {
                  ...styles[':active'],
                  backgroundColor: !isDisabled && (isSelected ? '#cfcfcf' : '#e3e3e3')
                }
              }
            }
          }}
        />
      </div>
      <ErrorMessage
        name={field.name}
        render={msg =>
          <div className="help-block">
            {msg}
          </div>}
      />
    </div>
  )
}

export default MultiSelectField
