import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import Markdown from 'react-markdown'

class AccordionElement extends React.Component {
  render() {
    const { question, answer, allowMarkdown = false } = this.props
    return (
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="row">
                <div className="col-md-12">
                  <span className="accordion__arrow" />
                </div>
                <span className="title__text ml-5">
                  <Markdown source={question} />
                </span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {allowMarkdown
              ? <Markdown source={answer} />
              : <div dangerouslySetInnerHTML={{ __html: answer }} />}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}

export default AccordionElement
