import React from 'react'
import { ErrorMessage, getIn } from 'formik'

const InputField = ({
  field,
  className,
  label,
  labelClassName,
  form: { touched, errors },
  ...props
}) => {
  return (
    <div
      className={`form-group ${getIn(errors, field.name) && getIn(touched, field.name)
        ? 'has-error'
        : ''}`}
    >
      {label &&
        <label htmlFor={field.name} className={`control-label ${labelClassName || ''}`}>
          {label}
        </label>}
      <input
        {...field}
        {...props}
        className={`form-control ${className || ''}        
          ${getIn(errors, field.name) && getIn(touched, field.name) ? 'has-error' : ''}
        `}
        autoComplete={props.autoComplete}
      />
      <ErrorMessage
        name={field.name}
        render={msg =>
          <div className="help-block" data-qa="input-error">
            {msg}
          </div>}
      />
    </div>
  )
}

export default InputField
