import React, { useState, useEffect } from "react";
import { getHCP, editHcpState, getPracticesByOrganizationId } from "../../../services/ManageHCP";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import Loading from "../../loading";
import HCPTable from "./organizationTable";
import ErrorResponse from "../../common/errorResponse";
import Pagination from "react-js-pagination";
import { Formik, Form, Field } from "formik";
import queryString from "query-string";
import SelectField from "../../../formFields/SelectField";
import SearchBySelectField from "../../../formFields/SearchBySelectField";
import { withRouter } from "../../../helper/withrouter";

import {
    HcpStatusFilterOption,
    HcpManagementFilter,
    PageSize,
} from "../../../helper/constants";
import ShowEntrySelectField from "../../../formFields/ShowEntrySelectField";
import _ from "lodash";

export const HCPManagement = ({ navigate, location }) => {
    const search = queryString.parse(location.search).search || "";
    const filter = queryString.parse(location.search).filter || "";
    const status = queryString.parse(location.search).status || "";
    const pageSize = queryString.parse(location.search).size || "";
    const searchWithFilter = `${filter}:${search}`;

    const [hcp, setHCP] = useState([]);
    const [practices, setPractices] = useState(new Map());
    const [pagination, setPagination] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [arePracticesLoading, setArePracticesLoading] = useState(false);
    const [statusCode, setStatusCode] = useState(200);
    const [sort, setSort] = useState("countryCode asc");
    const [sortOrderForPractices, setSortOrderForPractices] = useState("noColumn,noOrder");
    const [hcpStatus, setHcpStatus] = useState(status || HcpStatusFilterOption.All);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize || 25);
    const [searchTerm, setSearchTerm] = useState(search);

    const [hcpFilter, setHcpFilter] = useState(
        filter || HcpManagementFilter["Organization Name"]
    );
    const [isSearchActive, setIsSearchActive] = useState(false || search);
    const [queryParams, setQueryParams] = useState({
        order: "desc",
        size: currentPageSize,
        sort: "createdAt",
        state: status ? status : HcpStatusFilterOption.All,
        page: 0,
        search: search ? searchWithFilter : "",
    });

    const fetchHCP = async (param) => {
        setIsLoading(true);
        const hcpData = await getHCP(param);
        processApiResult(hcpData);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchHCP(queryParams);
    }, []);

    const processApiResult = (hcpData) => {
        if (hcpData && hcpData.statusCode === 200) {
            setHCP(hcpData.data.content);
            setPagination(hcpData.data.cursor);
        } else {
            setStatusCode(hcpData && hcpData.statusCode);
        }
    };

    const setSorting = async (sortField) => {
        setIsLoading(true);
        const order = queryParams.order === "asc" ? "desc" : "asc";
        setSort(sortField);
        const newParams = {
            ...queryParams,
            order: order,
            sort: sortField,
        };
        setQueryParams(newParams);
        await fetchHCP(newParams);
        setIsLoading(false);
    };

    const setPage = async (pageNumber) => {
        const page = pageNumber - 1;
        setQueryParams({
            ...queryParams,
            page: page,
        });
        setIsLoading(true);
        await fetchHCP({
            ...queryParams,
            page: page,
        });
        setIsLoading(false);
    };

    const initialValues = () => ({
        hcpStatus: hcpStatus,
        currentPageSize: currentPageSize,
    });
    const onHcpStatusChange = async (state) => {
        const parsed = queryString.parse(location.search);
        parsed.status = state;
        location.search = queryString.stringify(parsed);
        navigate(location);
        const filter = {
            ...queryParams,
            state: state,
            page: 0,
        };
        setHcpStatus(state);
        setQueryParams(filter);
        setIsLoading(true);
        await fetchHCP(filter);
        setIsLoading(false);
    };

    const onNoOfEntriesToBeShownChange = async (entry) => {
        const parsed = queryString.parse(location.search);
        parsed.size = entry;
        location.search = queryString.stringify(parsed);
        navigate(location);
        const filter = {
            ...queryParams,
            size: entry,
            page: 0,
        };
        setCurrentPageSize(entry);
        setQueryParams(filter);
        setIsLoading(true);
        await fetchHCP(filter);
        setIsLoading(false);
    };

    const onHcpStateChange = async (id, state) => {
        setIsLoading(true);
        await editHcpState(id, state);
        await fetchHCP(queryParams);
        setIsLoading(false);
    };

    const handleSearchText = (text) => {
        setSearchTerm(text);
        setQueryParams({
            ...queryParams,
            search: text,
        });
    };

    const handleSearch = async (state) => {
        const filter = {
            ...queryParams,
            state: hcpStatus,
        };
        filter["search"] = `${state ? state : hcpFilter}:${searchTerm}`;
        setQueryParams(filter);
        setIsLoading(true);
        await fetchHCP(filter);
        setIsLoading(false);
    };

    const onFilterChange = async (state) => {
        setHcpFilter(state);
        const parsed = queryString.parse(location.search);
        parsed.filter = state;
        location.search = queryString.stringify(parsed);
        navigate(location);
        if (searchTerm) {
            await handleSearch(state);
        }
    };

    const getOrganizationPracticeData = async (data) => {
        let elementsToBePutToTop;
        let practicesByOrganizationId;
        if (!practices.has(data?.organizationId)) {
            setArePracticesLoading(true);
            practicesByOrganizationId = await getPracticesByOrganizationId(
                data.organizationId
            );
            setArePracticesLoading(false);
        } else {
            practices.get(data?.organizationId).data.forEach((element) => {
                delete element.isHighlighted;
            });
            practicesByOrganizationId = practices.get(data?.organizationId);
        }
        const searchedSigniaAccountNumber = queryParams?.search?.split(":")[1];
        if (!_.isNil(searchedSigniaAccountNumber)) {
            elementsToBePutToTop = _.remove(
                practicesByOrganizationId?.data,
                (element) =>
                    element.wsaAccountNumber
                        .toLowerCase()
                        .includes(searchedSigniaAccountNumber?.toLowerCase())
            );
            if (!_.isNil(elementsToBePutToTop)) {
                elementsToBePutToTop.forEach((x) => (x.isHighlighted = true));
                practicesByOrganizationId.data.unshift(...elementsToBePutToTop);
            }
        }
        let tempMap = new Map(practices);
        tempMap.set(data?.organizationId, practicesByOrganizationId);
        setPractices(tempMap);
    };

    const sortPractices = (sortColumn, organizationId) => {
        const order = sortOrderForPractices.split(",")[1];
        const sortedList = _.orderBy(practices.get(organizationId)?.data, [val => val[sortColumn].toLowerCase()], [order]);
        let tempMap = new Map(practices);
        tempMap.set(organizationId, { data: sortedList });
        setSortOrderForPractices(`${sortColumn},${order === 'asc' ? 'desc' : 'asc'}`);
        setPractices(tempMap);
    }

    const getPageableComponent = () =>
        pagination ? (
            <Pagination
                hideDisabled
                activePage={pagination?.pageNumber + 1}
                itemsCountPerPage={pagination?.pageSize}
                totalItemsCount={pagination?.totalElements ?? 25}
                onChange={setPage}
            />
        ) : (
            <span>No records found</span>
        );

    if (statusCode !== 200) return <ErrorResponse statusCode={statusCode} />;
    return (
        <div className="container-fluid m-5">
            {isLoading ? (
                <Loading isLoading={isLoading} />
            ) : (
                <>
                    <div className="col-md-2 pl-0">
                        <h2>
                            <b>Telecare Users</b>
                        </h2>
                    </div>
                    <Formik initialValues={initialValues()} enableReinitialize={true}>
                        <Form>
                            <div className="row">
                                <div className="col-md-4">
                                    <Field
                                        name="hcpStatus"
                                        component={SelectField}
                                        label={"HCP Status"}
                                        options={Object.entries(HcpStatusFilterOption).map(
                                            (value) => ({
                                                value: value[1],
                                                label: value[0],
                                            })
                                        )}
                                        onSelect={onHcpStatusChange}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <SearchBySelectField
                                        handleSearch={handleSearch}
                                        searchTerm={searchTerm}
                                        hcpFilter={hcpFilter}
                                        handleSearchText={handleSearchText}
                                        onFilterChange={onFilterChange}
                                        isSearchActive={isSearchActive}
                                        setIsSearchActive={setIsSearchActive}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <Field
                                        name="currentPageSize"
                                        component={ShowEntrySelectField}
                                        options={PageSize.map((value) => ({
                                            value: value,
                                            label: value,
                                        }))}
                                        selectClassName="col-md-1 m-1"
                                        onSelect={onNoOfEntriesToBeShownChange}
                                    />
                                </div>
                            </div>
                        </Form>
                    </Formik>
                    <HCPTable
                        hcp={hcp}
                        sort={sort}
                        setSorting={setSorting}
                        order={queryParams.order}
                        onStateChange={onHcpStateChange}
                        onAccordionElementChange={getOrganizationPracticeData}
                        practices={practices}
                        isLoading={arePracticesLoading}
                        sortPractices={sortPractices}
                        sortOrderForPractices={sortOrderForPractices}
                    />
                    <div className="d-flex justify-content-center">
                        {getPageableComponent()}
                    </div>
                </>
            )}
        </div>
    );
};

export default withAuthorization(
    withRouter(HCPManagement),
    PageAuthorizations.HCPManagement.authorizedRoles
);
