import React, { useEffect, useState } from "react";
import { Field } from "formik";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"

import CheckboxField from "./CheckboxField";
import { getCountryListWithNames, getContinentsWithName, getContinentsWithCountry } from "../helper/countryList"

import 'react-accessible-accordion/dist/fancy-example.css';

const CountryByContinentSelectField = props => {
  
  const [countriesByContinents, setcountriesByContinents] = useState({});
  const accordionIdToExpand = []

  const countryWithNames = getCountryListWithNames()
  const continentsWithName = getContinentsWithName()
  const continentsData = getContinentsWithCountry()

  useEffect(() => {
    getCountriesByContinents();
  }, [props.form.values.countries]);

  const getCountriesByContinents = () => {
    const countriesData = Object.keys(props.form.values.countries);

    const countriesByContinentsData = Object.keys(continentsData).reduce(
      (memo, continentCode) => {
        const continentCountries = continentsData[continentCode];

        countriesData.forEach((countryCode) => {
          if (continentCountries.indexOf(countryCode.toUpperCase()) > -1) {
            if (!memo[continentCode]) {
              memo[continentCode] = {};
            }

            memo[continentCode][countryCode] = countryCode;
          }
        });

        return memo;
      },
      {}
    );
    setcountriesByContinents(countriesByContinentsData);
  };

  const { label, labelClassName, hint, preExpanded, form: { errors, touched, values } } = props;

  const continents = Object.keys(countriesByContinents).map((continentCode, index) => {
    const continentCountries = countriesByContinents[continentCode];
    const countries = Object.keys(continentCountries).map((countryCode) => {
      if(values[countryCode] && !accordionIdToExpand.includes(continentCode)) {
        accordionIdToExpand.push(continentCode)
      }
      return (
        <Field
          name={countryCode}
          isDisabled={props.disabled}
          key={countryCode}
          component={CheckboxField}
          label={countryWithNames[countryCode]}
          options={{
            description: {
              key: countryWithNames[countryCode],
            },
          }}
        />
      );
    });
    
    return (
      <div className="col-md-3 mt-2" key={continentCode}>
        <Accordion allowZeroExpanded={true} preExpanded={preExpanded ? [] : accordionIdToExpand}>
        <AccordionItem uuid={continentCode}>
          <AccordionItemHeading>
          <AccordionItemButton>
             <b>{continentsWithName[continentCode.toUpperCase()]}</b>
          </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
             {countries}
          </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  });

  return (
    <div className={'form-group ' + (touched && errors && errors.countries ? 'has-error' : '')}>
    <label className={labelClassName}><b>{label}</b></label>
    {touched && errors && errors.countries && (
        <span data-qa="input-error" className="help-block">{errors[0]}</span>
    )}
    {hint && (
        <span className="help-block">{hint}</span>
    )}
    <div className="row">
        {continents}
    </div>
</div>
  );
};

export default CountryByContinentSelectField;
