import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withAuthorization } from "../../higher-order-components/AuthorizationHOC";
import { PageAuthorizations } from "../../../auth/pageAuthorization";
import InputField from "../../../formFields/InputField";
import AutoCompleteField from "../../../formFields/AutoCompleteField";
import { getCountryListWithNames } from "../../../helper/countryList";
import { Button } from "react-bootstrap";
import { getCountries } from "../../../services/ManageCountry";
import {
  addAccountManager,
  searchUserByEmail,
} from "../../../services/ManageAccountManagers";
import Loading from "../../loading";
import CountryByContinentSelectField from "../../../formFields/CountryByContinentSelectField";
import _ from "lodash";
import { RadioButtonGroup } from '../../common/radioButtonGroup'
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'

let SupportId = getSupportId()

export const CreateAccount = (props) => {
  const [countryListWithNames, setCountryListWithNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [usersAutoList, setUsersAutoList] = useState([]);
  const [busy, setBusy] = useState(false);
  const [isCountrySelectionDisabled, setIsCountrySelectionDisabled] = useState(false);
  const [disbaleTcRegistration, setDisbaleTcRegistration] = useState(false);

  const countries = getCountryListWithNames();
  const roleOption = ['AMBASSADOR', 'ADMIN', 'SUPPORT']
  const roleOptionLabels = [
    'User',
    'Admin',
    'Support'
  ]

  const fetchCountries = async () => {
    let countryList = [];
    setIsLoading(true);
    const correlationContextCountry = CorrelationContext["ManageCountry_GetCountries"] + SupportId;
    countryList = await getCountries(correlationContextCountry);
    setIsLoading(false);
    setCountryListWithNames(getCountriesWithName(countryList.data));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const accountMangerValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter email id")
      .strict()
      .email("Please enter a valid email"),
  });

  const getCountriesWithName = (countryList) => {
    let countryData = {};
    countryList.forEach((element) => {
      let data = Object.entries(countries);
      for (let [key, value] of data) {
        if (key === element.countryCode) {
          countryData[key] = value;
        }
      }
    });
    return countryData;
  };

  const onSubmit = (values, setFieldError) => {
    const selectedEmail = _.find(usersAutoList, ["userPrincipalName", values.email]);
    if (!selectedEmail) {
      setFieldError("email", "Email Address not registered with WSA");
      return;
    }
    const accountManagerDetails = {
      firstName: selectedEmail.givenName,
      lastName: selectedEmail.surname,
      email: values.email,
      language: "EN",
      role: values.role,
      isTcRegisterNotificationEnabled: values.tcRegistrationNotificationEnabled,
      countries: Object.keys(values).filter((data) => {
        return data !== "tcRegistrationNotificationEnabled" && values[data] === true;
      }),
    };
    if (accountManagerDetails["countries"].length === 0) {
      setFieldError("countries", "Please select country");
    } else {
      setBusy(true);
      const correlationContext = CorrelationContext["ManageUsers_EditAccountManager"] + SupportId;
      return addAccountManager(accountManagerDetails, correlationContext)
        .then((response) => {
          setBusy(false);
          if (response.error_code === "EMAIL_ALREADY_EXISTS") {
            setFieldError("email", "This email already exists");
          } else {
            props.history.push({ pathname: `/accounts` });
          }
        })
        .catch((response) => {
          console.log("error", response);
        });
    }
  };

  const onCancel = () => {
    props.history.push({ pathname: `/accounts` });
  };

  const prepareAccountData = () => {
    let selectedCountry = {};
    let role = 'AMBASSADOR'
    Object.keys(countryListWithNames).forEach((countryCode) => {
      selectedCountry[countryCode] = false;
    });
    return {
      firstName: "",
      lastName: "",
      email: "",
      countries: countryListWithNames,
      tcRegistrationNotificationEnabled: true,
      ...selectedCountry,
      role
    };
  };

  const correlationContextByEmail = CorrelationContext["ManageUsers_SearchUserByEmail"] + SupportId;

  const handleEmailSelection = (user, setFieldValue, setFieldError) => {
    setFieldValue("email", user.userPrincipalName);
    setFieldValue("firstName", user.givenName);
    setFieldValue("lastName", user.surname);
  };

  const fetchOrganizationUsers = useCallback(
    _.debounce((searchText) => {
      searchUserByEmail(searchText, correlationContextByEmail).then((response) => {
        if (response && response.length > 0) {
          setUsersAutoList(response);
        }
      });
    }, 500),
    []
  );

  const onEmailFieldValueChanged = (e) => {
    const searchText = e;
    const selectedEmail = _.find(usersAutoList, ["mail", searchText]);
    if (
      searchText &&
      searchText !== "" &&
      searchText.length >= 3 &&
      !selectedEmail
    ) {
      fetchOrganizationUsers(searchText);
    }
  };

  const onRadioSelect = (e, setFieldValue, values) => {
    if (e.target.value == roleOption[2]) {
      handleCountrySelection(true, values, true, setFieldValue)
      handleTcRegistrationNotificationSelection(true, false, setFieldValue)
    }
    else if (e.target.value == roleOption[1]) {
      handleCountrySelection(false, values, true, setFieldValue)
      handleTcRegistrationNotificationSelection(false, false, setFieldValue)
    }
    else {
      handleCountrySelection(false, values, false, setFieldValue)
      handleTcRegistrationNotificationSelection(false, true, setFieldValue)
    }
  }

  const handleTcRegistrationNotificationSelection = (disabled, isChecked, setFieldValue) => {
    setDisbaleTcRegistration(disabled)
    setFieldValue('tcRegistrationNotificationEnabled', isChecked)
  }

  const handleCountrySelection = (disableCountrySelection, values, checkAllCountries, setFieldValue) => {
    setIsCountrySelectionDisabled(disableCountrySelection)
    Object.keys(values).filter((data) => {
      if (data !== "tcRegistrationNotificationEnabled" && (values[data] === true || values[data] === false)) {
        setFieldValue(data, checkAllCountries)
      }
    })
  }

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="container-fluid">
          <div className="row m-5">
            <Formik
              onSubmit={(values, actions) =>
                onSubmit(values, actions.setFieldError)
              }
              validationSchema={accountMangerValidationSchema}
              enableReinitialize={true}
              initialValues={prepareAccountData()}
            >
              {({ setFieldValue }) => {
                return (
                  <Form className="container-fluid">
                    <div>
                      <div>
                        <div>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="firstName"
                                label="First Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"first name"}
                                disabled={true}
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                name="lastName"
                                label="Last Name"
                                type="text"
                                className="input-wrapper"
                                component={InputField}
                                placeholder={"last name"}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <Field
                                name="email"
                                label="E-mail *"
                                type="text"
                                className="input-wrapper"
                                component={AutoCompleteField}
                                placeholder={"email"}
                                onInputValueChange={(searchText) =>
                                  onEmailFieldValueChanged(searchText)
                                }
                                userlist={usersAutoList}
                                onItemSelected={(user) =>
                                  handleEmailSelection(user, setFieldValue)
                                }
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <Field
                                disabled={false}
                                name="role"
                                component={RadioButtonGroup}
                                header={'Role'}
                                options={roleOption}
                                labelArray={roleOptionLabels}
                                onSelect={onRadioSelect}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 form-group notificationEnabled">
                              <Field
                                name="tcRegistrationNotificationEnabled"
                                key="tcRegistrationNotificationEnabled"
                                component={({ field }) => {
                                  return (
                                    <div className="admin_role_form">
                                      <input
                                        {...field}
                                        type="checkbox"
                                        className="checkbox-default"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          field.onChange(e);
                                          field.onBlur(e);
                                        }}
                                        defaultChecked={field.value}
                                        disabled={disbaleTcRegistration}
                                      />
                                      <label className="ml-3">
                                        <b>Enable Registration Notification</b>
                                      </label>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name="countries"
                                component={CountryByContinentSelectField}
                                label="Select Markets *"
                                preExpanded={true}
                                disabled={isCountrySelectionDisabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2 offset-md-8 form-group">
                          <button
                            className="btn btn-secondary btn-block"
                            type="button"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-2 form-group">
                          <Button
                            className="primary-button-edit-country"
                            variant="success"
                            type="submit"
                          >
                            Save
                            {busy && (
                              <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuthorization(
  CreateAccount,
  PageAuthorizations.CreateAccount.authorizedRoles
);
