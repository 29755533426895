import React from "react"
import { getReleaseNotes } from '../../services/ReleaseNotesService'

export function withReleaseNotes(WrappedComponent, count) {
  return class extends React.Component {
    state = {
      releaseNotes: []
    }

    async componentDidMount() {
      this.setState({
        releaseNotes: await getReleaseNotes(count)
      })
    }

    render() {
      const { releaseNotes } = this.state
      return releaseNotes.length > 0 && <WrappedComponent releaseNotes={releaseNotes} />
    }
  }
}