import React, {Component} from 'react';

export class NotAuthorizedPage extends Component {

  render() {
    return (
      <div>
        <h1>Sorry you are not authorized to access this page.</h1>
      </div>
    );
  }
}
