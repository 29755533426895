import React, { useState } from 'react'
import ManageAccounts from "../../../services/ManageAccounts";
import { Button } from "react-bootstrap";
import { getSupportId } from '../../../helper/ApplicationInsightsHelper'
import { CorrelationContext } from '../../../helper/constants'

let SupportId = getSupportId()
const ResetAccountTable = ({ accounts = [], isTenatAccountDetails, isUserPresentForTelecareFeature, isTC3ButtonAccessibleForCurrentRole, updateTelecareFeature }) => {

  const [resetPasswordLink, setResetPasswordLink] = useState('');

  const onRequestPassword = async username => {
    try {
      const correlationContext = CorrelationContext["ManageAccounts_PasswordResetByUserName"] + SupportId;
      const response = await ManageAccounts.requestPasswordResetByUsername(username, correlationContext);

      const data = await response.json();

      setResetPasswordLink(data.resetLink);
    } catch (e) {
      console.error(e);
    }
  }

  const TableHead = ({ isTenatAccountDetails }) => {
    if (isTenatAccountDetails) {
      return <th className="col-1" name="userType">
        User Type
      </th>
    }
    return <>
      <th className="col-2" name="userRole">User Role</th>
      <th className="col-1" name="organizationId" style={{ paddingRight: "1rem" }}>Organisation Id</th>
      <th className="col-1" name="emailVerification">Email Verification</th>
      <th className="col-1" name="phoneNoVerfication">Phone Verification</th>
    </>
  }

  const TableData = ({ account, isTenatAccountDetails }) => {
    if (isTenatAccountDetails) {
      return <td >
        {calcUserType(account.userType)}
      </td>
    }
    return <>
      <td >
        {account.role}
      </td>
      <td className='pl-3'>
        {account.orgId}
      </td>
      <td className='pl-3'>
        {getVerificationContent(account.email, account.isEmailVerified)}
      </td>
      <td className='pl-3'>
        {getVerificationContent(account.phone, account.isPhoneNumberVerified)}
      </td>
    </>
  }

  const calcAccountState = (state) => {
    switch (state) {
      case 0:
        return 'Active';
      case 1:
        return 'Disabled';
      case 2:
        return 'Migrated';
      case 3:
        return 'Pending';
      case 4:
        return 'Registration rejected';
      default:
        return ""
    }
  }

  const calcUserType = (type) => {
    switch (type) {
      case 0:
        return "Shop (HCP)";
      case 1:
        return "Employee";
      case 2:
        return "Customer";
      case 3:
        return "Account Manager";
      default:
        return ""
    }
  }

  const getButtonContent = (account) => {
    if (!isTenatAccountDetails) {
      return <tr>
        <td colSpan={4}></td>
        {calcAccountState(account.accountState) !== 'Pending' &&
          <td style={{ paddingRight: "unset" }} colSpan={1}>
            {resetPasswordLink === '' ?
              <Button onClick={() => onRequestPassword(account.userId)}>Create ResetPassword Link</Button> :
              <p>{resetPasswordLink}</p>}
          </td>}
        {calcAccountState(account.accountState) === 'Active' && isTC3ButtonAccessibleForCurrentRole && (
          <td colSpan={1}>
            <Button onClick={() => updateTelecareFeature(account.userName, !isUserPresentForTelecareFeature)}>{`${isUserPresentForTelecareFeature ? 'Disable' : 'Enable'} TC3.0`}</Button>
          </td>)}
      </tr>
    }
  }

  const getVerificationContent = (field, isVerified) => {
    if (!field) {
      return <></>
    }
    return isVerified ? <i class="fa fa-check" aria-hidden="true" title='verifed'></i> :
      <i className="fa fa-times " aria-hidden="true" title='unverifed'></i>;
  }
  return (
    <table className={`table table--users " + ${!isTenatAccountDetails ? "serach-account" : " "}`}>
      <thead>
        <tr>
          <th className="col-2" name="userName">Username</th>
          <th className="col-2" name="email">Email</th>
          {!isTenatAccountDetails &&
            <th className="col-1" name="phone" >Phone</th>
          }
          <th className="col-1" name="accountState"> Account State</th>
          <TableHead isTenatAccountDetails={isTenatAccountDetails} />
        </tr>
      </thead>
      <tbody>
        {Object.keys(accounts).map((id) => {
          const account = accounts[id];
          return (
            <>
              <tr key={account.userName}>
                <td>
                  {account.userName}
                </td>
                <td>
                  {account.email}
                </td>
                {!isTenatAccountDetails && <> <td>
                  {account.phone}
                </td></>}
                <td>
                  {calcAccountState(account.accountState)}
                </td>
                <TableData account={account} isTenatAccountDetails={isTenatAccountDetails} />
              </tr>
              {getButtonContent(account)}
            </>
          );

        })}
      </tbody>
    </table>
  )
}

export default ResetAccountTable
